import qs from "query-string";

const permission = {};

//운영관리자
permission.isOperationMgr = (roles) => {
  return roles[0] ? roles[0]?.roleIdx === 1 : false;
};

//미디어관리자
permission.isMediaMgr = (roles) => {
  return roles[0] ? roles[0]?.roleIdx === 2 : false;
};

//조직관리자
permission.isOrganizationMgr = (roles) => {
  return roles[0] ? roles[0]?.roleIdx === 3 : false;
};

//사이트관리자
permission.isSite = (roles) => {
  let check = false;
  let keys = Object.keys(roles);
  for (let i in keys) {
    if (roles[keys[i]]?.roleIdx == 4) {
      check = true;
    }
  }
  return check;
};

//이벤트담당자
permission.isEvent = (roles) => {
  let check = false;
  let keys = Object.keys(roles);
  for (let i in keys) {
    if (roles[keys[i]]?.roleIdx == 5) {
      check = true;
    }
  }
  return check;
};

//등록담당자
permission.isRegister = (roles, siteIdx) => {
  return roles[siteIdx] ? roles[siteIdx]?.roleIdx === 6 : false;
};

//에이전시담당자
permission.isAgency = (roles, siteIdx) => {
  return roles[siteIdx] ? roles[siteIdx]?.roleIdx === 7 : false;
};

//미디어담당자
permission.isMedia = (roles, siteIdx) => {
  return roles[siteIdx] ? roles[siteIdx]?.roleIdx === 8 : false;
};

//이벤트 관리자 이상의 권한
permission.isAboveEventMgr = (roles) => {
  const check = (
    permission.isOperationMgr(roles) ||
    permission.isMediaMgr(roles) ||
    permission.isOrganizationMgr(roles) ||
    permission.isSite(roles) ||
    permission.isEvent(roles));
  return check;
}


//생성,삭제,수정,읽기,전체수정,전체읽기 권한 확인
/**
 *
 * @param {*} iamRoles 소유한 권한 리스트
 * @param {*} siteIdx 사이트 index
 * @param {*} partialCode PROFILE 테이블 code의 일부분 ex) SITE_R, SITE_RU => SITE
 * @param {*} perm create,read,modify,delete
 * @returns
 */
permission.check = (iamRoles, siteIdx, partialCode, perm) => {
  if (siteIdx == null || siteIdx == undefined) return;

  const basicSiteCode = ["SITE", "RESOURCE", "PRESENTER", "LOCATION", "MEDIA"];
  const basicResourceCode = ["RESOURCE", "PRESENTER", "LOCATION", "MEDIA"];
  const basicEventCode = [
    "EVENT_BASIC", // 이벤트 기본정보, 이벤트 상세정보, 세션관리
    "EVENT_SESSION",
    "PARTICIPANT_REG",
    "PARTICIPANT_INVITE",
    "PARTICIPANT_CHANGE",
    "PARTICIPANT_LIVE",
    "PARTICIPANT_THANKS",
    "PARTICIPANT_ALL",
    "EVENT_FEEDBACK",
    "PARTICIPANT_REPORT",
    "FEEDBACK_REPORT",
    "EMAIL_SEND",
    "SMS_SEND",
    "TALK_SEND",
  ];

  //조직관리자 이상의 권한인지 체크, 하위권한이면 사이트 단위로 권한 체크
  const index = iamRoles[0] ? 0 : siteIdx;

  if (basicSiteCode.indexOf(partialCode) >= 0) {
    //사이트 전체에 대한 권한 여부 체크
    let siteRoles = iamRoles[index]?.permissions?.filter((p) =>
      p?.code?.includes("SITE")
    );
    if (siteRoles.filter((r) => r[perm]).length > 0) return true;
  }

  if (basicResourceCode.indexOf(partialCode) >= 0) {
    //사이트 전체에 대한 권한 여부 체크
    let resourceRoles = iamRoles[index]?.permissions?.filter(
      (p) => p?.code?.includes("SITE") || p?.code?.includes("RESOURCE")
    );
    if (resourceRoles.filter((r) => r[perm]).length > 0) return true;
  }

  // 이벤트 권한 (모든참가자보기,수정 권한 제외)
  if (
    basicEventCode.indexOf(partialCode) >= 0 &&
    partialCode !== "PARTICIPANT_ALL"
  ) {
    let siteRoles = iamRoles[index]?.permissions?.filter((p) =>
      p?.code?.includes("SITE")
    );
    if (siteRoles.filter((r) => r[perm]).length > 0) return true;

    let eventRoles = iamRoles[index]?.permissions?.filter(
      (p) => p?.code == "EVENT_CRUD"
    );

    if (eventRoles.filter((r) => r[perm]).length > 0) return true;

    if (partialCode == "EVENT_BASIC") {
      let eventMenuRoles = iamRoles[index]?.permissions?.filter((p) =>
        p?.category?.includes("EVENT")
      );
      if (eventMenuRoles.filter((r) => r[perm]).length > 0) return true;
    }

    //이벤트 하위 전체메뉴에 대한 권한 여부 체크
    let eventMenuRoles = iamRoles[index]?.permissions?.filter((p) =>
      p?.code?.includes("EVENT_SESSION")
    );
    if (eventMenuRoles.filter((r) => r[perm]).length > 0) return true;
  }

  let roles = iamRoles[index]?.permissions?.filter(
    (p) =>
      // p?.code?.includes(partialCode)
      p?.code == partialCode + "_CRUD" ||
      p?.code == partialCode + "_RU" ||
      p?.code == partialCode + "_R"
  );

  if (!roles || roles?.length <= 0) return false;

  const result = roles.filter((r) => r[perm]).length > 0;

  return iamRoles[index] ? result : false;
};


permission.getLink = (iamRoles, siteIdx, category) => {
  
  if (siteIdx == null || siteIdx == undefined) return;
  const index = iamRoles[0] ? 0 : siteIdx;

  let roles = iamRoles[index]?.permissions?.filter(
    (p) => p?.category == category && p?.type == "MENU" && p?.uri
  );

  let url = "";

  if (category == "EVENT") {
    let siteRoles = iamRoles[index]?.permissions?.filter(
      (p) =>
        p?.code == "SITE_R" || p?.code == "SITE_RU" || p?.code == "SITE_CRUD"
    );
    if (siteRoles?.length > 0) {
      return "/event/#EVENT_IDX/dashboard";
    }

    roles = iamRoles[index]?.permissions?.filter(
      (p) => p?.category == category
    );
    url = roles?.length > 0 ? "/event/#EVENT_IDX/dashboard" : "";
  } else {
    url = roles[0]?.uri;
  }

  return url;
};

//통합관리자
permission.isSystemAdmin = (roles) => {
  return roles?.SYSTEM_ADMIN != null;
}

//조직관리자
permission.isOrgAdmin = (roles) => {
  return roles?.ORG_ADMIN != null;
}

//사이트관리자
permission.isSiteAdmin = (roles) => {
  let result = false;
  if(roles?.SITE){
    let siteIdxs = Object.keys(roles.SITE);
    for(let i in siteIdxs){
      result = roles.SITE[siteIdxs[i]]?.SITE_ADMIN != null;
      if(result) break;
    }
  }
  return result;
}
//이벤트관리자
permission.isEventMgr = (roles) => {
  let result = false;
  if(roles?.SITE){
    let siteIdxs = Object.keys(roles.SITE);
    for(let i in siteIdxs){
      result = roles.SITE[siteIdxs[i]]?.EVENT_MGR != null;
      if(result) break;
    }
  }
  return result;
}
//이벤트사용자
permission.isEventUser = (roles) => {
  let result = false;
  if(roles?.SITE){
    let siteIdxs = Object.keys(roles.SITE);
    for(let i in siteIdxs){
      result = roles.SITE[siteIdxs[i]]?.EVENT_USER != null;
      if(result) break;
    }
  }
  return result;
}


//이벤트 운영담당자
permission.isEventOper = (roles) => {
  let result = false;
  if(roles?.SITE){
    let siteIdxs = Object.keys(roles.SITE);
    for(let i in siteIdxs){
      if(!result){
        let eventIdxs = roles.SITE[siteIdxs[i]]?.EVENT;
        for(let j in eventIdxs){
          result = roles.SITE[siteIdxs[i]]?.EVENT[eventIdxs[j]]?.EVENT_OPER != null;
          if(result) break;
        }
      }
    }
  }
  return result;
}
//이벤트 등록담당자
permission.isEventSales = (roles) => {
  let result = false;
  if(roles?.SITE){
    let siteIdxs = Object.keys(roles.SITE);
    for(let i in siteIdxs){
      if(!result){
        let eventIdxs = roles.SITE[siteIdxs[i]]?.EVENT;
        for(let j in eventIdxs){
          result = roles.SITE[siteIdxs[i]]?.EVENT[eventIdxs[j]]?.EVENT_SALES != null;
          if(result) break;
        }
      }
    }
  }
  return result;
}
//이벤트 미디어담당자
permission.isEventMedia = (roles) => {
  let result = false;
  if(roles?.SITE){
    let siteIdxs = Object.keys(roles.SITE);
    for(let i in siteIdxs){
      if(!result){
        let eventIdxs = roles.SITE[siteIdxs[i]]?.EVENT;
        for(let j in eventIdxs){
          result = roles.SITE[siteIdxs[i]]?.EVENT[eventIdxs[j]]?.EVENT_MEDIA != null;
          if(result) break;
        }
      }
    }
  }
  return result;
}
//이벤트 이벤트뷰어
permission.isEventViewer = (roles) => {
  return roles?.EVENT?.EVENT_VIEWER != null;
}



//캠페인 운영담당자
permission.isCampaignOper = (roles) => {
  let result = false;
  if(roles?.SITE){
    let siteIdxs = Object.keys(roles.SITE);
    for(let i in siteIdxs){
      if(!result){
        let campaignIdxs = roles.SITE[siteIdxs[i]]?.CAMPAIGN;
        for(let j in campaignIdxs){
          result = roles.SITE[siteIdxs[i]]?.CAMPAIGN[campaignIdxs[j]]?.CAMPAIGN_OPER != null;
          if(result) break;
        }
      }
    }
  }
  return result;
}

//켐페인 등록담당자
permission.isCampaignSales = (roles) => {
  let result = false;
  if(roles?.SITE){
    let siteIdxs = Object.keys(roles.SITE);
    for(let i in siteIdxs){
      if(!result){
        let campaignIdxs = roles.SITE[siteIdxs[i]]?.CAMPAIGN;
        for(let j in campaignIdxs){
          result = roles.SITE[siteIdxs[i]]?.CAMPAIGN[campaignIdxs[j]]?.CAMPAIGN_SALES != null;
          if(result) break;
        }
      }
    }
  }
  return result;
}


//조직 관련 권한 소유 유무(GNB - 조직 메뉴 노출 여부에 사용)
permission.hasOrgRoles = (roles) => {
  if(permission.isSystemAdmin(roles)){
    return true;
  }else{
    return roles?.ORG_ADMIN != null;
  }
}

//사이트 관련 권한 소유 유무(GNB - 사이트 메뉴 노출 여부에 사용)
permission.hasSiteRoles = (roles) => {
  if(permission.hasOrgRoles(roles)) {
    return true;
  } else {
    return roles?.SITE != null;
  }
}

//이벤트 관련 권한 소유 유무(GNB - 이벤트 메뉴 노출 여부에 사용)
permission.hasEventRoles = (roles) => {
  if(permission.hasSiteRoles(roles)){
    return true;
  }else{
    let result = false;
    if(roles?.SITE){
      let siteIdxs = Object.keys(roles?.SITE);
      for(let i in siteIdxs){
        result = roles.SITE[siteIdxs[i]]?.EVENT != null;
        if(result) break;
      }
    }
    return result;
  }
}

//캠페인 관련 권한 소유 유무(GNB - 캠페인 메뉴 노출 여부에 사용)
permission.hasCampaignRoles = (roles) => {
  if(permission.hasSiteRoles(roles)){
    return true;
  }else{
    let result = false;
    if(roles?.SITE){
      let siteIdxs = Object.keys(roles?.SITE);
      for(let i in siteIdxs){
        result = roles.SITE[siteIdxs[i]]?.CAMPAIGN != null;
        if(result) break;
      }
    }
    return result;
  }
}

/**
 * 조직관리자 생성,읽기,수정,삭제 권한 체크(조직 리스트, 조직 기본정보, 상세정보, 부서, 팀 등등..)
 * @param {*} roles 사용자가 소유한 모든 권한
 * @param {*} companyIdx 조직 IDX
 * @param {*} partialCode PROFILE 테이블 code의 일부분 ex) SITE_R, SITE_RU => SITE
 * @param {*} perm create,read,modify,delete 중 하나
 * @returns 
 */
permission.orgCheck = (roles, companyIdx, partialCode, perm) => {
  //통합관리자일 경우 true
  if(roles?.SYSTEM_ADMIN) return true;

  if(!roles?.ORG_ADMIN || !roles?.ORG_ADMIN?.[companyIdx]) return false;

  let myPerms = roles?.ORG_ADMIN[companyIdx]?.filter((p) => 
    p?.code == partialCode + "_CRUD" ||
    p?.code == partialCode + "_RU" ||
    p?.code == partialCode + "_R"
  );

  if (!myPerms || myPerms?.length <= 0) return false;

  const result = myPerms.filter((r) => r[perm]).length > 0;

  return result;
}


/**
 * 사이트 관련 권한 생성,읽기,수정,삭제 권한 체크(사이트 기본정보, 상세정보, 리소스...)
 * @param {*} roles 사용자가 소유한 모든 권한
 * @param {*} companyIdx 사이트가 속한 조직 IDX
 * @param {*} siteIdx 사이트 IDX
 * @param {*} partialCode PROFILE 테이블 code의 일부분 ex) SITE_R, SITE_RU => SITE
 * @param {*} perm create,read,modify,delete 중 하나
 * @param {*} eventRoleCheck 이벤트 운영관리자, 등록담당자, 이벤트뷰어 등 사이트 관련 권한 체크 여부
 * @returns 
 */
permission.siteCheck = (roles, companyIdx, siteIdx, partialCode, perm, eventRoleCheck = true) => {

  //조직관리자 이상의 권한을 소유한 경우
  if(permission.orgCheck(roles, companyIdx, partialCode, perm)) return true;

  if(!roles?.SITE || !roles?.SITE[siteIdx]) return false;

  let roleCodes = Object.keys(roles?.SITE[siteIdx]); // ['SITE_ADMIN', 'EVENT_MGR', ...]
  
  let result = false;
  
  for (let i in roleCodes) {
    if(roleCodes[i] != 'EVENT'){
      if(roles.SITE[siteIdx]?.[roleCodes[i]]?.length > 0){ // roles.SITE.1.SITE_ADMIN
        let myPerms = roles.SITE[siteIdx][roleCodes[i]]?.filter((p) => 
          p?.code == partialCode + "_CRUD" ||
          p?.code == partialCode + "_RU" ||
          p?.code == partialCode + "_R"
        )
    
        if (myPerms && myPerms?.length > 0){
          result = myPerms.filter((r) => r[perm]).length > 0;
          if(result) break;
        }
      }
    }
  }


  if(eventRoleCheck && !result){

    if(!roles.SITE || !roles.SITE[siteIdx] || !roles.SITE[siteIdx].EVENT) return false;

    let eventIdxs = Object.keys(roles.SITE[siteIdx].EVENT);
    for(let i in eventIdxs){
      if(!result){
        let eventRoleCodes = Object.keys(roles.SITE[siteIdx].EVENT[eventIdxs[i]]);
        for(let j in eventRoleCodes){
          if(!result){
            let myPerms = roles.SITE[siteIdx]?.EVENT[eventIdxs[i]]?.[eventRoleCodes[j]]?.filter((p) => 
              p?.code == partialCode + "_CRUD" ||
              p?.code == partialCode + "_RU" ||
              p?.code == partialCode + "_R"
            )
        
            if (myPerms && myPerms?.length > 0){
              result = myPerms.filter((r) => r[perm]).length > 0;
              if(result) break;
            }
          }
        }
      }
    }
  }

  return result;
}


/**
 * 이벤트 관련 권한 생성,읽기,수정,삭제 권한 체크(이벤트 기본정보, 상세정보, 세션, 참가자...)
 * @param {*} roles 사용자가 소유한 모든 권한
 * @param {*} companyIdx 이벤트가 속한 조직 IDX
 * @param {*} siteIdx 이벤트가 속한 사이트 IDX
 * @param {*} eventIdx 이벤트 IDX
 * @param {*} partialCode PROFILE 테이블 code의 일부분 ex) SITE_R, SITE_RU => SITE
 * @param {*} perm create,read,modify,delete 중 하나
 * @returns 
 */
permission.eventCheck = (roles, companyIdx, siteIdx, eventIdx, partialCode, perm) => {

  //사이트관리자 이상의 권한을 소유한 경우
  if(permission.siteCheck(roles, companyIdx, siteIdx, partialCode, perm, false)) return true;

  if(
    !roles?.SITE || 
    !roles?.SITE[siteIdx] || 
    !roles?.SITE[siteIdx]?.EVENT || 
    !roles?.SITE[siteIdx]?.EVENT[eventIdx]
  ) {
    return false;
  }

  let roleCodes = Object.keys(roles.SITE[siteIdx].EVENT[eventIdx]); // ['EVENT_OPER', 'EVENT_SALES', ...]

  let result = false;

  for (let i in roleCodes) {

    if(roles.SITE[siteIdx].EVENT[eventIdx]?.[roleCodes[i]]?.length > 0){ // roles.SITE.1.EVENT.1.EVENT_OPER
      let myPerms = roles.SITE[siteIdx]?.EVENT[eventIdx]?.[roleCodes[i]]?.filter((p) => 
        p?.code == partialCode + "_CRUD" ||
        p?.code == partialCode + "_RU" ||
        p?.code == partialCode + "_R"
      )
  
      if (myPerms && myPerms?.length > 0){
        result = myPerms.filter((r) => r[perm]).length > 0;
        if(result) {
          console.log('result =', result);
          break; 
        }
      }
    }
  }

  return result;
}



/**
 * 캠페인 관련 권한 생성,읽기,수정,삭제 권한 체크(캠페인 기본정보, 입력항목, 참가자...)
 * @param {*} roles 사용자가 소유한 모든 권한
 * @param {*} companyIdx 캠페인이 속한 조직 IDX
 * @param {*} siteIdx 캠페인이 속한 사이트 IDX
 * @param {*} campaginIdx 캠페인 IDX
 * @param {*} partialCode PROFILE 테이블 code의 일부분 ex) SITE_R, SITE_RU => SITE
 * @param {*} perm create,read,modify,delete 중 하나
 * @returns 
 */
permission.campaignCheck = (roles, companyIdx, siteIdx, campaginIdx, partialCode, perm) => {

  //사이트관리자 이상의 권한을 소유한 경우
  if(permission.siteCheck(roles, companyIdx, siteIdx, partialCode, perm, false)) return true;

  if(
    !roles?.SITE || 
    !roles?.SITE[siteIdx] || 
    !roles?.SITE[siteIdx]?.CAMPAIGN || 
    !roles?.SITE[siteIdx]?.CAMPAIGN[campaginIdx]
  ) {
    return false;
  }

  let roleCodes = Object.keys(roles.SITE[siteIdx].CAMPAIGN[campaginIdx]); // ['EVENT_OPER', 'EVENT_SALES', ...]

  let result = false;

  for (let i in roleCodes) {

    if(roles.SITE[siteIdx].CAMPAIGN[campaginIdx]?.[roleCodes[i]]?.length > 0){ // roles.SITE.1.EVENT.1.EVENT_OPER
      let myPerms = roles.SITE[siteIdx]?.CAMPAIGN[campaginIdx]?.[roleCodes[i]]?.filter((p) => 
        p?.code == partialCode + "_CRUD" ||
        p?.code == partialCode + "_RU" ||
        p?.code == partialCode + "_R"
      )
  
      if (myPerms && myPerms?.length > 0){
        result = myPerms.filter((r) => r[perm]).length > 0;
        if(result) {
          console.log('result =', result);
          break; 
        }
      }
    }
  }

  return result;
}

export default permission;
