import React, {useState, useContext,useEffect,useRef } from "react";
import { AppContext } from "../AppProvider";
import styled from "styled-components";
import { Fragment } from "react";
import imgArrowCircleDown from "../../resources/preReg/images/ebooth/arrow_circle_down.svg";
import imgExample from "../../resources/preReg/images/ebooth/img4.png";
import imgExample1 from "../../resources/preReg/images/ebooth/image 8.png";
import imgComplete from "../../resources/preReg/images/ebooth/complete.svg";
import imgClose from "../../resources/preReg/images/ebooth/close.svg";
import imgCheck from "../../resources/preReg/images/ebooth/Checkbox_wrapper.svg";
import imgBackbtn from "../../resources/preReg/images/ebooth/backbtn.svg";
import imgOpenIn from "../../resources/preReg/images/ebooth/open_in_new.svg";
import imgOpenIn2 from "../../resources/preReg/images/ebooth/open_in_new2.svg";
import useStore from "../../modules/utils/store";
import { _API, _CK } from "../../modules/utils";
import moment from "moment";
import Cookies from "universal-cookie";

const EboothModal = (props) => {
  const { siteInfo } = useStore();
  const { iam } = useContext(AppContext);
  const cm = new Cookies();
  const [hideToday, setHideToday] = useState(cm.get(iam.preregIdx) != null ? true : false);
  const [viewDetail, setViewDetail] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  const { setEboothModalShow, eboothSiteSetting, eboothEventSetting, eboothSectionItem, eboothItem, eboothList,loadEboothInfo } = props;
 
  // Detail 정보
  const [eboothIdx, setEeboothIdx] = useState(false);
  const [attendYn, setAttendYn] = useState(false);
  const [detailTitle, setDetailTitle] = useState(false);
  const [detailImg, setDetailImg] = useState(false);
  const [detailMore, setDetailMore] = useState(false);
  const [detailMoreUrl, setDetailMoreUrl] = useState(false);
  const [detailCompany, setDetailCompany] = useState(false);
  const [detailTel, setDetailTel] = useState(false);
  const [detailEmail, setDetailEmail] = useState(false);
  const [detailHomepage, setDetailHomepage] = useState(false);
  const [detailMoreShow, setDetailMoreShow] = useState(false);
  const [detailCompanyShow, setDetailCompanyShow] = useState(false);
  const [detailTelShow, setDetailTelShow] = useState(false);
  const [detailEmailShow, setDetailEmailShow] = useState(false);
  const [detailHomepageShow, setDetailHomepageShow] = useState(false);
  const [detailContents, setDetailContents] = useState("");
  
  const hasTitle = eboothList?.some(item => item.title);
  const modalClose = () => {
    setEboothModalShow(false)
  };
  const handleCheckboxChange = () => {
    const today = !hideToday
    if(today == true){
      //하루는 86400초
      cm.set(iam.preregIdx, {cDate : moment().format('YYYY.MM.DD'), eventIdx : siteInfo.idx}, { path: "/", sameSite: "lax", maxAge: 86400});
    }else{
      cm.remove(iam.preregIdx)
    }
    setHideToday(today);
  };
  const itemRefs = useRef([]);
  const handleScroll = (index) => {
    // targetRef.current이 실제 DOM 엘리먼트를 참조합니다.
    // 예를 들어, 해당 엘리먼트로 스크롤 이동하는 코드는 다음과 같을 수 있습니다.
    if (itemRefs.current[index]) {
      itemRefs.current[index].scrollIntoView({ behavior: 'smooth' });
    }
  };
  const saveAfter = (name) => {
    loadEboothInfo();
  };

  const gotoDetail = (item) => {
    setViewDetail(true)
    if(item?.attendYn == "N" && eboothEventSetting?.attendCompleteStandard == "ACCESS"){
      const req = {
        path: "/v1/client/ebooth/attend",
        data: {
          eboothIdx : item.idx
          ,preregIdx : iam.preregIdx
        },
      };
      _API.post(req).then((res) => {
        const { insertId } = res.data;
        console.log(res.data)
        if(insertId > 0){
          saveAfter();
        }
      }).catch((e) => {
        console.log(e)
      });
    }
    setEeboothIdx(item.idx == null ? "" : item.idx)
    setAttendYn(item.attendYn == null ? "" : item.attendYn)
    setDetailTitle(item.name == null ? "" : item.name)
    setDetailImg(item.img == null ? "" : item.img)
    setDetailMore(item.detailViewLabel == null ? "" : item.detailViewLabel)
    setDetailMoreUrl(item.detailViewUrl == null ? "" : item.detailViewUrl)
    setDetailCompany(item.companyName == null ? "" : item.companyName)
    setDetailTel(item.tel == null ? "" : item.tel)
    setDetailEmail(item.email == null ? "" : item.email)
    setDetailHomepage(item.homepageUrl == null ? "" : item.homepageUrl)
    setDetailMoreShow(item.detailViewShow == null || item.detailViewShow == 'N' ? false : true)
    setDetailCompanyShow(item.companyNameShow == null || item.companyNameShow == 'N' ? false : true)
    setDetailTelShow(item.telShow == null || item.telShow == 'N' ? false : true)
    setDetailEmailShow(item.emailShow == null || item.emailShow == 'N' ? false : true)
    setDetailHomepageShow(item.homepageUrlShow == null || item.homepageUrlShow == 'N' ? false : true)
    setDetailContents(item.contents)
  };
  
  const detailMoreClick = (eboothIdx,attendYn,detailMoreUrl) => {
    if(attendYn == "N" && eboothEventSetting?.attendCompleteStandard == "DETAIL"){
      const req = {
        path: "/v1/client/ebooth/attend",
        data: {
          eboothIdx : eboothIdx
          ,preregIdx : iam.preregIdx
        },
      };
      _API.post(req).then((res) => {
        const { insertId } = res.data;
        console.log(res.data)
        if(insertId > 0){
          saveAfter();
        }
      }).catch((e) => {
        console.log(e)
      });
    }
    const url = detailMoreUrl.substring(0, 7) == "http://" || detailMoreUrl.substring(0, 8) == "https://" ? detailMoreUrl : `http://`+detailMoreUrl;
    window.open('about:blank').location.href =  url;
  }
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767);
    };

    // 초기 마운트 시에 이벤트 핸들러 등록
    window.addEventListener('resize', handleResize);

    // 컴포넌트가 언마운트될 때 이벤트 핸들러 해제
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  return (
    <Fragment>
      <EboothContainer className="ebooth_container">
        {eboothEventSetting != null &&
        <>
          <div className="header">
            {!viewDetail ? (
              <h2>{eboothEventSetting?.pageName}</h2>
            ) : (
              <div>
                <img src={imgBackbtn} style={{cursor:"pointer"}} onClick={()=>{setViewDetail(false)}} />
                <h2>{detailTitle}</h2>
              </div>
            )}
            <button className="close_btn" onClick={modalClose}><img src={imgClose}/></button>
          </div>
          {(hasTitle && !viewDetail) && (
            <ul className="tag_list">
              {eboothList?.map((item,index) => (
                item?.title && (
                  <li>
                    <a onClick={() => handleScroll(index)}>
                      {item?.title}
                      <img src={imgArrowCircleDown} />
                    </a>
                  </li>)
              ))}
            </ul>
          )}
          <div className="content">
            {!viewDetail ? (
              <>
                {(eboothEventSetting.intro != null && eboothEventSetting.intro != "") &&
                  <p className="ebooth_desc" dangerouslySetInnerHTML={{ __html: eboothEventSetting.intro }}>
                  </p>
                }
                <div className="ebooth_content_inner">
                  {eboothList?.map((item, index) => (
                    <div class="content_cont" key={index} ref={(el) => (itemRefs.current[index] = el)}>
                      {(eboothEventSetting.sectionUse == "Y") && (eboothEventSetting.sectionNameUse == "Y") ? (<h3>{item?.title}</h3>) : "" }
                      {(eboothEventSetting.sectionUse == "Y") && (eboothEventSetting.sectionIntroUse == "Y") ? (<span dangerouslySetInnerHTML={{ __html: item?.desc }}></span>) : "" }
                      <div class="content_cont_inner">
                        <ul class="content_list">
                          {item.list.map((j,index) => (
                            <li
                              style={{
                                width: `${item.column == "2" ? "calc(50% - 18px)"
                                  :item.column == "3" ? "calc(33.33% - 24px)"
                                  : item.column == "4" ? "calc(25% - 27px)" : ""}`,
                                flex: `${item.column == "2" && isMobile ? "0 0 80%"
                                  :item.column == "3" && isMobile ? "0 0 70%"
                                  :item.column == "4" && isMobile ? "0 0 60%":""}`
                              }}
                            >
                              <div className="content_list_inner" onClick={()=>{gotoDetail(j)}}>
                                <div>
                                  <img src={j?.img} className="photo_img" />
                                  {(j.attendYn == "Y" && eboothEventSetting?.attendCompleteStampUse == "Y") && (
                                    <div className="complete_box">
                                      <img src={imgComplete} className="complete_img" />
                                    </div>
                                  )}
                                </div>
                              </div>
                              <h4>{j?.name}</h4>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            ) : (
              <div className="view_detail">
                {/* <img src={detailImg}/> */}
                <div dangerouslySetInnerHTML={{ __html: detailContents }} ></div>
                {detailMoreShow && <button><a onClick={()=>{detailMoreClick(eboothIdx,attendYn,detailMoreUrl)}} target={`_blank`} >{detailMore}</a></button>}
                  {(detailCompanyShow || detailTelShow || detailEmailShow || detailHomepageShow) && (
                  <ul>
                    {detailCompanyShow && <li><h5>Company</h5><p>{detailCompany}</p></li>}
                    {detailTelShow && <li><h5>Contact</h5><p>{detailTel}</p></li>}
                    {detailEmailShow && <li><h5>Email</h5><p>{detailEmail}</p></li>}
                    {detailHomepageShow && 
                    <li>
                      <h5>Homepage</h5>
                      <p>
                        <a href={detailHomepage.substring(0, 7) == "http://" || detailHomepage.substring(0, 8) == "https://" ? detailHomepage : `http://`+detailHomepage} target={`_blank`} >
                          {detailHomepage}<img src={imgOpenIn2} />
                        </a>
                      </p>
                    </li>
                  }
                  </ul>  
                )}
              </div>
            )}
          </div>
          <div className="btm">
              <label className="checkbox-label">
                <input
                  type="checkbox"
                  checked={hideToday}
                  onChange={(e) => handleCheckboxChange(e)}
                  className="checkbox-input"
                
                />
                {hideToday ? (
                  <img src={imgCheck}/>
                ):<span className="checkbox-custom"></span>}
                <span className="checkbox-custom-text">Don't see it for today</span>
              </label>
            <div className="close_box"><button onClick={modalClose}>Close</button></div>
          </div>
        </>
        }
      </EboothContainer>
    </Fragment>
  );
};

export default EboothModal;


const EboothContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display:flex;
  flex-direction: column;
  width: 90%;
  max-width: 1240px;
  max-height: calc(100vh - 120px);
  height: 100%;
  border-radius: 6px;
  overflow-y:auto;
  background: #fff;
  gap:20px;
  
  @media (orientation: landscape) and (max-width: 950px) and (max-device-height: 500px){
    top:unset;
    left:unset;
    transform:unset;
    width:100%;
    height:100%;
  }
  img {display:block;}
  .header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px 30px 0 30px;
  }
  .header h2{font-size: 26px; font-weight: 900; line-height: 34px; color:#1a1a1a;}
  .tag_list{display:flex; align-items: center; justify-content: flex-start; gap:4px; margin: 0 30px;}
  .tag_list li{background: #EBEBF8; border-radius:4px;}
  .tag_list li a{display:flex; align-items: center; color:#464f60; font-size:13px; font-weight:500; line-height:18px; gap: 4px; padding: 6px 8px; cursor:pointer;}
  .tag_list li a:hover,.tag_list li a:focus{color:#464f60;}
  .content{
    flex: 1 1 auto; 
    overflow-y:auto;
    &::-webkit-scrollbar {
      width: 6px;
      border-radius: 4px;
      background: #EFEFEF;
    }
    &::-webkit-scrollbar-thumb {
      background: #A1A1A1;
      border-radius: 4px;
    }
  }
  .content .ebooth_desc{
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    color:#646464;
    margin:0 30px 20px 30px;
    padding-bottom:20px;
  }
  .ebooth_content_inner{display:flex; flex-direction:column; row-gap:60px; margin-bottom:60px; padding-bottom:20px;}
  .content_cont h3{
    display: flex;
    align-items: center;
    flex-basis: 100%;
    font-size: 24px;
    font-weight: 700;
    line-height: 33px;
    color:#5959EF;
    margin:0 30px 24px 30px
  }
  .content_cont h3:before{
    content: "";
    flex-grow: 1;
    width: auto;
    height: 1px;
    background: #5959EF;
    font-size: 0;
    line-height: 0;
    margin-right: 30px;
  }
  .content_cont h3:after{
    content: "";
    flex-grow: 1;
    width: auto;
    height: 1px;
    background: #5959EF;
    font-size: 0;
    line-height: 0;
    margin-left: 30px;
  }
  .content_cont span{display:block; font-size: 15px; font-weight: 400;
line-height: 22px; color:#646464; margin:0 30px 24px 30px;}
  .content_list{
    display:flex;
    flex-wrap:wrap;
    column-gap: 36px;
    row-gap: 40px;
    margin:0 30px;
    &::-webkit-scrollbar {
      height: 6px;
      border-radius: 4px;
      background: #EFEFEF;
    }
    &::-webkit-scrollbar-thumb {
      background: #A1A1A1;
      border-radius: 4px;
    }
  }
  .content_list li > .content_list_inner{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    cursor:pointer;
  }
  .content_list li > .content_list_inner:after{
    content: "";
    display: block;
    padding-bottom: 100%;
  }
  .content_list li > .content_list_inner > div{
    position:absolute; display: flex;
    align-items: center; height:100%; background:#F0EFF6; width:100%;
  }
  .content_list li > h4{
    margin-top:20px;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px; 
    color:#1a1a1a;
  }
  .content_list li img.photo_img{width:100%}
  .content_list li .complete_box{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255,255,255,0.8);
  }
  .content_list li img.complete_img{position: absolute; bottom: 3.5%; right: 3.5%; width: 35%; font-size: 0;}
  .btm{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 12px;
    border-top: 1px solid #D9D9D9;
    padding: 20px 0;
    margin:0 30px
  }
  .checkbox-container {
    display: flex;
    align-items: center;
  }

  .checkbox-label {
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: flex-end;
    width: fit-content;
  }
  .checkbox-label img{
    margin-right:8px;
  }
  .checkbox-input {
    display: none;
  }

  .checkbox-custom {
    width: 14px;
    height: 14px;
    border: 1px solid #D7D7D7;
    border-radius: 2px;
    margin-right: 6px;
    display: block;
    position: relative;
    background-size: cover;
  }
  .checkbox-custom-text{
    font-size: 15px;
    font-weight: 400;
    line-height: 22px;
    color:#646464;
  }
  .btm .close_box button{
    padding:8px 16px;
    font-size: 15px;
    font-weight: 400;
    line-height: 22px; 
    color:#464f60;
    background:#EBEBF8;
    border-radius:4px;
  }

  /* 디테일*/
  .header div{display:flex; align-items: center; gap:8px;}
  .content .view_detail img {width:100%;}
  .content .view_detail > button{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px auto;
    background: #5959EF;
    border-radius:4px;
    gap:4px;
    padding:8px;
    font-size: 15px;
    font-weight: 400;
    line-height: 22px; 
    color:#fff;
  }
  .content .view_detail > button *{color:#fff;}
  .content .view_detail{margin:0 30px;}
  .content .view_detail > ul{display:flex; flex-direction:column; gap:20px; border-top:1px solid #D9D9D9; padding:20px 0;}
  .content .view_detail > ul li{display:flex; align-items:center; gap:25px;}
  .content .view_detail > ul li h5{width:60px;font-size: 15px;
    font-weight: 400;
    line-height: 22px;color:#464f60;}
  .content .view_detail > ul li p{font-size: 15px;
    font-weight: 400;
    line-height: 22px; color:#464f60;}
  .content .view_detail > ul li p:before{content:""; display:inline-block; margin:0 12px; width:1px; height:10px; background:#d9d9d9;}
  .content .view_detail > ul li p{display:flex; align-items:center;  }
  .content .view_detail > ul li p a{display:flex; align-items:center; gap:4px; color:#5959EF; border-bottom: 1px dotted #5959ef; }
  
  @media (max-width: 767px) {
    width:100%;
    max-height: 100dvh;
    border-radius:0;
    .header{padding:20px 20px 0 20px}
    .header h2{font-size:20px;}
    .tag_list{margin:0 20px;}
    .content .ebooth_desc{margin:0 20px 20px 20px}
    .ebooth_content_inner{margin-bottom:30px;}
    .content_cont h3{margin:0 20px 20px 20px}
    .content_cont span{margin: 0 20px 24px 20px;}
    .content_cont_inner{margin-right:20px;}
    .content_list{flex-wrap: nowrap; column-gap:12px; margin:0; margin-left:20px; padding-bottom:20px; overflow-x:auto; overflow-y:hidden;}
    .btm{margin:0 20px;}
    .content .view_detail{padding:0 20px;}
  }
`;