import React,{Fragment, useState, useEffect} from 'react';
import moment from "moment";
import { _CFG } from '../../../../modules/config';
import { _API, _CK, _U } from "../../../../modules/utils"
import styled from 'styled-components';
import Modal from '../../../../components/Modal/Modal';

const TabButton = styled.div`
  width:50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  position: relative;
  gap: 6px;
  padding: 12px 32px;
  border-style: solid;
  cursor: pointer;
  transition: all 0.3s;
  background: ${props => props.active ? "#ffffff" : "#FaFaFa"};
  border-color: ${props => props.active ? "#404040" : "#dedede"};

  &:hover {
    background: #FAFAFA;
    border-color: #404040;
  }

  ${props => props.left && `
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border-width: 1px;
    border-right-width: ${props.active ? '1px' : '0'};
  `}

  ${props => props.right && `
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-width: 1px;
  `}
`;

const TabText = styled.p`
  flex-grow: 0;
  flex-shrink: 0;
  font-size: 13px;
  text-align: left;
  color: #5f5f5f;
  line-height: ${props => props.right ? "1.5" : "inherit"};
`;

const InputWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;

  &:before {
    content: "${props => props.prefix}";
    position: absolute;
    left: 20px;
    color: #888888;
    font-size: 14px;
    pointer-events: none;
  }

  input {
    padding-left: ${props => (props.prefix.length * 8) + 20}px !important;
  }
`;



const ModalDescription = styled.p`
  color: #646464;
  font-size: 13px;
  line-height: 20px;
  margin-bottom: 20px;
`;

const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 24px;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const InputLabel = styled.div`
  font-size: 14px;
  color: #646464;
`;

const ModalInput = styled.input`
  width: 100%;
  padding: 12px;
  border: 1px solid #d7d7d7;
  border-radius: 4px;
  font-size: 14px;

  &::placeholder {
    color: #e1e1e1;
  }
`;

const ConfirmButton = styled.button`
  width: 100%;
  padding:14px 12px;
  background: #5959EF;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
`;

const SupportInfo = styled.div`
  position: relative;
  margin-top: 34px;
  padding-top: 8px;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0%;
    width: 12px;
    height: 2px;
    background-color: #1a1a1a;
  }

  h3 {
    font-size: 13px;
    font-weight: 700;
    margin-bottom: 16px;
    color:#404040;
  }

  p {
    font-size: 13px;
    color: #646464;
    line-height: 21px;
  }
`;

const SuccessMessage = styled.div`
  display:flex;
  flex-direction: column;
  row-gap: 16px;
  text-align: left;
  padding-bottom: 16px;
  p {
    font-size: 14px;
    color: #646464;
    line-height: 1.5;
  }
  span{
    display:block;
    font-size: 13px;
    color: #404040;
    line-height: 16px;
    border-bottom: 1px dotted #5959ef;
    width: fit-content;
    cursor: pointer;
    &:hover {
      color: #5959ef;
    }
  }
`;

const EmailFormatButton = styled.button`
  display: inline-block;
  font-size: 13px;
  color: #5959ef;
  background: none;
  border: 1px solid #5959ef;
  border-radius: 4px;
  padding: 8px 16px;
  margin-top: 8px;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    background: #5959ef;
    color: white;
  }
`;

const EmailFormatContent = styled.div`
  padding: 20px;
  background: #f8f8f8;
  border-radius: 8px;
  margin-bottom: 20px;

  h4 {
    font-size: 14px;
    color: #404040;
    margin-bottom: 12px;
    font-weight: 600;
  }

  p {
    font-size: 13px;
    color: #646464;
    line-height: 1.6;
    margin-bottom: 8px;
  }
  span{
    border-bottom: none;
    display: inline-block;
  }
`;

const LoginPasscodeView = (props) => { 
  const { 
    info, passCodeOpt, passcodeNext, isPasswordVisible,
    passCodePrivacyOpen, passCodeUseOpen, passCodeOverseaOpen, onLoginClick, onSetInfo,
    preregEventList, setIsPasswordVisible, goEventSessionList, goMypage, passCodeShowTerms, 
    setPassCodeUseAgree, setPassCodePrivacyAgree, setPassCodeOverseaAgree, pscmethod, setPscmethod, setPassCodeOverseaOpen,
    passCodePrivacyAgree, passCodeUseAgree, passCodeOverseaAgree, passType , setPassCodeUseOpen, setPassCodePrivacyOpen
  } = props;

  const [allChecked, setAllChecked] = useState(false);
  const [searchData, setSearchData] = useState({});
  // const [pscmethod, setPscmethod] = useState('passCode');
  const termsCount = [passCodePrivacyOpen, passCodeUseOpen, passCodeOverseaOpen].filter(Boolean).length;

  // 모달 상태 관리를 위한 state 추가
  const [showFindPasscodeModal, setShowFindPasscodeModal] = useState(false);

  // 모달 단계를 관리하는 state 추가
  const [modalStep, setModalStep] = useState('form'); // 'form' | 'success'

  // 재전송 상태 추가
  const [isResending, setIsResending] = useState(false);
  // 이메일 형식 보기 상태 추가
  const [showEmailFormat, setShowEmailFormat] = useState(false);

  const onEmailCheck = (value) => {
    setSearchItem("email", value)
  }

  const setSearchItem = (key, value) => {

    const newVal = value ? value.toString() : '';

    setSearchData((o) => {
      return { ...o, [key]: newVal };
    });

  };

  // 모달 열기 함수 수정
  const openFindPasscodeModal = () => {
    setShowFindPasscodeModal(true);
    document.body.style.overflow = "hidden";
  };

  // 모달 닫기 함수 수정
  const closeFindPasscodeModal = () => {
    setShowFindPasscodeModal(false);
    setModalStep('form'); 
    setSearchItem('name', '');
    setSearchItem('email', '');
    document.body.style.overflow = "auto";
  };

  // 확인 버튼 클릭 핸들러
  const handleConfirm = () => {

    if(!searchData?.name) {
      alert('이름을 입력해 주세요.');
      return false;
    }

    if(!searchData?.email) {
      alert('이메일을 입력해 주세요.');
      return false;
    }

    if(!_U.isEmail(searchData?.email)) {
      alert('이메일 형식의 맞지 않습니다.')
      return false;
    }

    const req = {
      path: "/v1/client/accessCode/search",
      data: { 
              name: searchData?.name,
              email: searchData?.email,
              siteIdx: info?.idx
            },
     };

     _API.post(req).then((res) => {

      const { data } = res.data;

      if(data) {
        setModalStep('success');
      } else {
        alert('메일발송의 실패했습니다.');
      }
    });
  };

  // 이메일 재전송 핸들러 수정
  const handleResendEmail = () => {
    setIsResending(true);
    handleConfirm();
    setTimeout(() => {
      setIsResending(false);
    }, 2000);
  };

  // 이메일 형식 보기 핸들러 수정
  const handleShowEmailFormat = () => {
    setShowEmailFormat(true);
    setIsResending(false);
  };

  const handleAllCheckedChange = (value) => {
    setAllChecked(value);
    setPassCodePrivacyAgree(value);
    setPassCodeUseAgree(value);
    setPassCodeOverseaAgree(value);
  };

  useEffect(() => {
    const allCheckedState =
      (passCodePrivacyOpen ? passCodePrivacyAgree : true) &&
      (passCodeUseOpen ? passCodeUseAgree : true) &&
      (passCodeOverseaOpen ? passCodeOverseaAgree : true) &&
      (passCodePrivacyOpen || passCodeUseOpen || passCodeOverseaOpen);

    setAllChecked(allCheckedState);
  }, [passCodePrivacyAgree, passCodeUseAgree, passCodeOverseaAgree]);

   useEffect(() => {
    if(pscmethod) {
        setPassCodeUseOpen(false)
        setPassCodePrivacyOpen(false)
        setPassCodeOverseaOpen(false)
    }
  },[pscmethod]) 

  return (
    <div className="login_box psc_box">
      {!passcodeNext && (
        <div className="psc_box1">
          {info?.accessCodeUse == 'Y' && (
            <div style={{ display: "flex",justifyContent: "flex-start",alignItems: "flex-start",alignSelf: "stretch",flexGrow: 0,flexShrink: 0,}}>
              <TabButton 
                left 
                active={pscmethod === 'passCode'}
                onClick={() => setPscmethod('passCode')}
              >
              <TabText>{info?.passType == 'MOBILE' ? '모바일번호' : info?.passType == 'EMAIL' ? '이메일' : '테스트'}</TabText>
              </TabButton>
              <TabButton 
                right
                active={pscmethod === 'unique'}
                onClick={() => setPscmethod('unique')}
              >
                <TabText right>참가고유코드</TabText>
              </TabButton>
            </div>
          )}
          

          {pscmethod=='passCode' ? (
            <>
              {passCodeOpt[0]?.topPhraseFormUse !== 'N' ? (
                <div dangerouslySetInnerHTML={{__html: passCodeOpt[0]?.topPhraseForm}}/>
              ) : ''}
                <div className="login_input_div">
                  <input
                    className="login_input"
                    id="psc_password"
                    type={!isPasswordVisible ? "password" : "text"}
                    placeholder="패스코드를 입력해 주세요."
                    defaultValue=""
                    onKeyPress={(e) => {
                      if (e.key == "Enter"){
                        onLoginClick(e);
                      } 
                    }}
                  />
                    <img
                      src="https://cdn.onoffevent.com/login/icon-s-password.svg"
                      onClick={(e)=>setIsPasswordVisible(!isPasswordVisible)}
                      style={{opacity:`${isPasswordVisible == true? "0.5":"1"}`,cursor:"pointer"}}
                    />
                </div>
                {(passCodePrivacyOpen == true || passCodeUseOpen == true || passCodeOverseaOpen == true) && (
                  <div className="agree_form">
                    <ul> 
                      {termsCount >= 2 && (
                        <li>
                          <p>
                            <input
                              type="checkbox"
                              id="login_agree_all"
                              checked={allChecked}
                              onChange={(e) => handleAllCheckedChange(e.target.checked)}
                            />
                            <label id="chk_label" htmlFor="login_agree_all">
                              <span></span>
                              모두 동의합니다.
                            </label>
                          </p>
                        </li>
                      )}
                      {passCodePrivacyOpen == true && (
                        <li>
                          <p>
                            <input type="checkbox" id="login_agree_box2" onChange={(e) => setPassCodePrivacyAgree(e.target.checked)} checked={passCodePrivacyAgree}/>
                            <label id="chk_label" htmlFor="login_agree_box2" >
                              <span></span>
                              {`${passCodeOpt[0].termsPrivacyFormLabel} ${passCodeOpt[0]?.termsPrivacyFormEsUse == 'Y' ? '(필수)' : '(선택)'}`}
                            </label>
                          </p>
                          <a style={{ cursor: "pointer",whiteSpace:"nowrap" }} onClick={passCodeShowTerms.bind(this, "termsPrivateAgree")}>
                            내용보기
                          </a>
                        </li>
                      )}
                      {passCodeOverseaOpen == true && (
                        <li>
                          <p>
                            <input type="checkbox" id="login_agree_box3" onChange={(e) => setPassCodeOverseaAgree(e.target.checked)} checked={passCodeOverseaAgree}/>
                            <label id="chk_label" htmlFor="login_agree_box3">
                              <span></span>
                              {/* {passCodeOpt[0].termsOverseaLabel} */}
                              {`${passCodeOpt[0]?.termsOverseaLabel} ${passCodeOpt[0]?.termsOverseaEsUse == 'Y' ? '(필수)' : '(선택)'}`}
                              </label>
                          </p>
                          <a style={{ cursor: "pointer",whiteSpace:"nowrap"}} onClick={passCodeShowTerms.bind(this,"termsOverseaAgree")}>
                            내용보기
                          </a>
                        </li>
                      )}
                      {passCodeUseOpen == true && (
                        <li>
                          <p>
                            <input type="checkbox" id="login_agree_box" onChange={(e) => setPassCodeUseAgree(e.target.checked)} checked={passCodeUseAgree}/>
                            <label id="chk_label" htmlFor="login_agree_box">
                              <span></span>
                              {/* {passCodeOpt[0].termsUseFormLabel} */}
                              {`${passCodeOpt[0]?.termsUseFormLabel} ${passCodeOpt[0]?.termsUseFormEsUse == 'Y' ? '(필수)' : '(선택)'}`}
                            </label>
                          </p>
                          <a style={{ cursor: "pointer" }} onClick={passCodeShowTerms.bind(this,"termsUseAgree")}>
                            내용보기
                          </a>
                        </li>
                      )}
                    </ul>
                  </div>
                )}
                <div className="login_enter">
                  <span id="passcode_submit_btn" onClick={(e) => onLoginClick(e)}>
                    {passCodeOpt[0] != null ? (passCodeOpt[0].loginBtnLabel != null && passCodeOpt[0].loginBtnLabel != "" ? passCodeOpt[0].loginBtnLabel : "심포지엄 확인하기") : "심포지엄 확인하기"}
                  </span>
                </div>
            </>
          )
          :pscmethod=='unique' ? (
            <>
           {passCodeOpt[0]?.accessFormUse !== 'N' ? (
                <div dangerouslySetInnerHTML={{__html: passCodeOpt[0]?.accessForm}}/>
              ) : ''}
              <div className="login_input_div">
                <InputWrapper prefix={`${info?.accessCodeName}-`}>
                  <input
                    className="login_input"
                    id="psc_unique"
                    type="text"
                    placeholder="참가고유코드를 입력해주세요."
                    defaultValue=""
                    onKeyPress={(e) => {
                      // 숫자가 아닌 경우 입력 방지
                      if (!/[0-9]/.test(e.key) && e.key !== "Enter") {
                        e.preventDefault();
                      }
                      if (e.key === "Enter") {
                        onLoginClick(e);
                      }
                    }}
                    onChange={(e) => {
                      // 숫자 외의 문자 제거
                      e.target.value = e.target.value.replace(/[^0-9]/g, '');
                    }}
                  />
                </InputWrapper>
              </div>

              {(passCodePrivacyOpen == true || passCodeUseOpen == true || passCodeOverseaOpen == true) && (
                <div className="agree_form">
                  <ul> 
                    {termsCount >= 2 && (
                      <li>
                        <p>
                          <input
                            type="checkbox"
                            id="login_agree_all"
                            checked={allChecked}
                            onChange={(e) => handleAllCheckedChange(e.target.checked)}
                          />
                          <label id="chk_label" htmlFor="login_agree_all">
                            <span></span>
                            모두 동의합니다.
                          </label>
                        </p>
                      </li>
                    )}
                    {passCodePrivacyOpen == true && (
                      <li>
                        <p>
                          <input type="checkbox" id="login_agree_box2" onChange={(e) => setPassCodePrivacyAgree(e.target.checked)} checked={passCodePrivacyAgree}/>
                          <label id="chk_label" htmlFor="login_agree_box2" >
                            <span></span>
                            {`${passCodeOpt[0].termsPrivacyFormLabel} ${passCodeOpt[0]?.termsPrivacyFormEsUse == 'Y' ? '(필수)' : '(선택)'}`}
                          </label>
                        </p>
                        <a style={{ cursor: "pointer",whiteSpace:"nowrap" }} onClick={passCodeShowTerms.bind(this, "termsPrivateAgree")}>
                          내용보기
                        </a>
                      </li>
                    )}
                    {passCodeOverseaOpen == true && (
                      <li>
                        <p>
                          <input type="checkbox" id="login_agree_box3" onChange={(e) => setPassCodeOverseaAgree(e.target.checked)} checked={passCodeOverseaAgree}/>
                          <label id="chk_label" htmlFor="login_agree_box3">
                            <span></span>
                            {/* {passCodeOpt[0].termsOverseaLabel} */}
                            {`${passCodeOpt[0]?.termsOverseaLabel} ${passCodeOpt[0]?.termsOverseaEsUse == 'Y' ? '(필수)' : '(선택)'}`}
                            </label>
                        </p>
                        <a style={{ cursor: "pointer",whiteSpace:"nowrap"}} onClick={passCodeShowTerms.bind(this,"termsOverseaAgree")}>
                          내용보기
                        </a>
                      </li>
                    )}
                    {passCodeUseOpen == true && (
                      <li>
                        <p>
                          <input type="checkbox" id="login_agree_box" onChange={(e) => setPassCodeUseAgree(e.target.checked)} checked={passCodeUseAgree}/>
                          <label id="chk_label" htmlFor="login_agree_box">
                            <span></span>
                            {/* {passCodeOpt[0].termsUseFormLabel} */}
                            {`${passCodeOpt[0]?.termsUseFormLabel} ${passCodeOpt[0]?.termsUseFormEsUse == 'Y' ? '(필수)' : '(선택)'}`}
                          </label>
                        </p>
                        <a style={{ cursor: "pointer" }} onClick={passCodeShowTerms.bind(this,"termsUseAgree")}>
                          내용보기
                        </a>
                      </li>
                    )}
                  </ul>
                </div>
              )}
              <div className="login_enter" style={{marginBottom:"0px"}}>
                <span id="passcode_submit_btn" onClick={(e) => onLoginClick(e)}>
                  {passCodeOpt[0] != null ? (passCodeOpt[0].loginBtnLabel != null && passCodeOpt[0].loginBtnLabel != "" ? passCodeOpt[0].loginBtnLabel : "심포지엄 확인하기") : "심포지엄 확인하기"}
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  flexGrow: 0,
                  flexShrink: 0,
                  position: "relative",
                  gap: 4,
                }}
              >
                <span 
                  style={{ flexGrow: 0, flexShrink: 0, fontSize: 13, lineHeight:"15px", textAlign: "left", color: "#404040", margin:"8px 0px", borderBottom:"1px dotted #5959ef", cursor:"pointer" }}
                  onClick={openFindPasscodeModal}
                >
                  참가고유코드 찾기
                </span>
              </div>
            </>
          ):""}
        </div>
      )}

      {passcodeNext && (
        <>
        {preregEventList?.length > 0 && (
          <div
            className="psc_box2"
            style={{ display: "block" }}
          >
            <p>
              총 {preregEventList.length}개의 이벤트에 등록이
              되어있습니다.
              <br />
              선택하신 이벤트로 입장하실 수 있습니다.
            </p>
            {preregEventList?.map((item, i) => (
              <Fragment key={i}>
                <div
                  className="login_session psc_session"
                  onClick={(e) =>
                    goEventSessionList(item, e)
                  }
                >
                  <div className="psc_hd">
                    <h4 className="login_session_h4">
                      <a>{item.name}</a>
                    </h4>
                    <p>
                      <img
                        src="https://cdn.onoffevent.com/common/arrow_select.svg"
                        alt="펼쳐보기"
                      />
                    </p>
                  </div>
                  {(item?.startDate && item.endDate) ? (
                  <p className="login_session_p">
                    {moment(item?.startDate).format(
                      "YYYY.MM.DD(ddd) HH:mm"
                    )}{" "}
                    ~{" "}
                    {moment(item?.endDate).format(`
                      ${moment(item?.startDate).format('YYYY.MM.DD') == moment(item?.endDate).format('YYYY.MM.DD') ? 'HH:mm' : 'YYYY.MM.DD(ddd) HH:mm'}`
                    )}
                  </p>
                  )
                  : ""
                    }
                </div>
              </Fragment>
            ))}
          </div>
        )}

          <a href="#" onClick={e => goMypage(e)}  style={{display:"block",textAlign:"right"}}>마이페이지</a>
        </>
      )}
      
      {/* 모달 컴포넌트 수정 */}
      <Modal
        isOpen={showFindPasscodeModal}
        onClose={closeFindPasscodeModal}
        title="참가고유코드 찾기"
        closeOnBackgroundClick={false}
      >
        {modalStep === 'form' ? (
          <>
            <ModalDescription>
              성명 및 이메일을 입력 후 [확인]을 클릭하시면 주세요.<br/>
              이메일로 참가고유코드를 발송해 드립니다.
            </ModalDescription>
            <InputGroup>
              <InputContainer>
                <InputLabel>성명</InputLabel>
                <ModalInput 
                  type="text" 
                  placeholder="성명을 입력해 주세요." 
                  onChange={e=> setSearchItem("name", e.target.value)}
                  value={searchData?.name}
                  onKeyDown={e => e.key === "Enter" && e.preventDefault()}
                />
              </InputContainer>
              <InputContainer>
                <InputLabel>이메일</InputLabel>
                <ModalInput 
                  type="text" 
                  placeholder="이메일을 입력해 주세요." 
                  onChange={e => onEmailCheck(e.target.value)}
                  value={searchData?.email}
                  onKeyDown={e => e.key === "Enter" && e.preventDefault()}
                />
              </InputContainer>
              <ConfirmButton onClick={handleConfirm}>확인</ConfirmButton>
            </InputGroup>
            <SupportInfo>
              <h3>심포지엄 사무국</h3>
              <p>도움이 필요하신가요?</p>
              <p>담당자: 02-1234-5678 (평일 09:00~18:00)</p>
              <p>이메일: support@webinars.co.kr</p>
            </SupportInfo>
          </>
        ) : (
          <>
            <SuccessMessage>
              {!isResending && !showEmailFormat ? (
                <>
                  <p>
                    참가고유코드를 발송해 드렸습니다.<br/><br/>
                    참가고유코드 발송은 최대 5분이 소요됩니다.<br/>
                    스팸 또는 휴지통 등 메일함을 확인해 주세요.<br/>          
                  </p>
                  <span onClick={handleResendEmail}>
                    이메일을 받지 못하셨나요? 다시받기
                  </span>
                </>
              ) : isResending ? (
                <p>
                  참가고유코드가 재발송되었습니다.<br/><br/>
                  메일이 도착하지 않는 경우<br/>
                  스팸메일함을 확인하시거나<br/>
                  심포지엄 사무국으로 문의해 주세요.<br/>
                </p>
              ):''}
            </SuccessMessage>
            <SupportInfo>
              <h3>심포지엄 사무국</h3>
              <p>도움이 필요하신가요?</p>
              <p>담당자: 02-1234-5678 (평일 09:00~18:00)</p>
              <p>이메일: support@webinars.co.kr</p>
            </SupportInfo>
          </>
        )}
      </Modal>
    </div>
  )
}

export default LoginPasscodeView;