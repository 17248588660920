import React from 'react';
import styled from 'styled-components';
import closeIcon from '../../resources/preReg/images/icon/modalClose.svg';

const ModalContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  border-radius: 8px;
  width: ${props => props.width || '420px'};
  z-index: 1000;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
`;

const ModalHeader = styled.div`
  padding: 20px 30px 24px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;

  h2 {
    font-size: 20px;
    font-weight: 500;
    color: #1a1a1a;
    margin: 0;
  }
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
`;

const ModalContent = styled.div`
  padding: 0 30px 30px;
`;

const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;

const Modal = ({ 
  isOpen, 
  onClose, 
  title, 
  children,
  width,
  closeOnBackgroundClick = true
}) => {
  if (!isOpen) return null;

  const handleBackgroundClick = () => {
    if (closeOnBackgroundClick) {
      onClose();
    }
  };

  return (
    <>
      <ModalContainer width={width}>
        <ModalHeader>
          <h2>{title}</h2>
          <CloseButton onClick={onClose}>
            <img src={closeIcon} alt="닫기" />
          </CloseButton>
        </ModalHeader>
        <ModalContent>
          {children}
        </ModalContent>
      </ModalContainer>
      <ModalBackground onClick={handleBackgroundClick} />
    </>
  );
};

export default Modal; 