import React, { useState, Fragment, useEffect, useRef } from 'react';
import cx from "classnames";
import { _API, _CK, _U } from "../../../../modules/utils";
import Header from '../../TypeA/component/Layout/Header';
import { Footer } from '../../TypeA/component/Layout/Footer';
import styled from "styled-components";
import HospitalFindDialog from '../../TypeA/component/Dialog/hospitalFindDialog'
import PreRegisterDialog from '../../TypeA/component/Dialog/PreRegisterDialog'
import EventDialog from '../../TypeA/component/Dialog/EventDialog'
import { ThemeProvider } from '@mui/material/styles';
import { theme } from '../../Campaign.theme'
import { DynamicStyles } from "../../../common/Resources/GlobalStyle";
import { Typography, Box, Stack } from "@mui/material";
import { MuiSelects } from "../../TypeA/component/UI/CampaignSelect";
import useMediaQuery from '@mui/material/useMediaQuery';
import Badge from "../../TypeA/component/UI/Badge"
import Input, { Checkbox, Hint, Radio, TextArea } from "../../TypeA/component/UI/Input"
import Button from "../../TypeA/component/UI/Button";
import SwiperCore, { Autoplay, Pagination, Navigation } from "swiper";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.scss";
import requiredImg from "../../../../resources/images/campaign/required.png";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/scrollbar/scrollbar.scss";
import arrow_circle_up from "../../../../resources/images/campaign/arrow_circle_up.svg"
import moment from "moment";

export default (props) => {
  const { history, match, campaignIdx } = props;
  const [info, setInfo] = useState({});
  const [hospitalOpen, setHospitalOpen] = useState(false);
  const [eventInformOpen, setEventInformOpen] = useState(false);
  const [preregOpen, setPreregOpen] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [curPreRegInfoList, setCurPreRegInfoList] = useState([]);
  const [selectConnectPath, setSelectConnectPath] = useState("");
  const [isExpanded, setIsExpanded] = useState(false);
  const [brandingInfo, setBrandingInfo] = useState({});
  const [templateInfo, setTemplateInfo] = useState({});
  const [defaultTemplateInfo, setDefaultTemplateInfo] = useState({});
  const [campaignInfo, setCampaignInfo] = useState({});
  const [eventList, setEventList] = useState([]);
  const [regFormInfo, setRegFormInfo] = useState({});
  const [inputItems, setInputItems] = useState([]);
  const [exReg, setExReg] = useState(false);
  const [heightCheck, setHeightCheck] = useState('N');
  const [choiceHospitalInputIdx, setChoiceHospitalInputIdx] = useState(null);
  const [teams, setTeams] = useState([]);
  const [offices, setOffices] = useState([]);
  const [managers, setManagers] = useState([]);
  const [mgrName, setMgrName] = useState('');
  const [loading, setLoading] = useState(true);
  const [endMsg, setEndMsg] = useState('');

  SwiperCore.use([Navigation, Pagination, Autoplay]);

  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const showIntroRef = useRef();
  const toggleExpansion = () => {
    setIsExpanded(!isExpanded);
  };
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });

  /*   useEffect(() => {
      const handleResize = () => {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight
        });
      };
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []); */

  const mobile = windowSize.width <= 787;

  /**
    * 팀 선택 리스트
    */

  const loadTeamList = (inputIdx) => {
    const req = {
      path: "/v1/campaign/team/list",
      data: {
        inputIdx,
        noAuthorCheck: true
      },

    };

    _API.post(req).then((res) => {
      const { data } = res?.data;
      setTeams(data);
    });
  }

  /**
   * 영업소 선택 리스트
   */

  const loadOfficeList = (inputIdx) => {
    const req = {
      path: "/v1/campaign/office/list",
      data: {
        inputIdx,
        noAuthorCheck: true
      },
    };

    _API.post(req).then((res) => {
      const { data } = res?.data;
      setOffices(data);
    });
  }

  /**
   * 담당자 선택 리스트
   */
  const loadMgrList = ({ inputIdx, teamIdx, officeIdx, siteHostLimit }) => {

    if (!teamIdx && !officeIdx) {
      handleChange(inputIdx, '');
      setManagers([]);
      return;
    }

    const req = {
      path: "/v1/campaign/mgr/list",
      data: {
        teamIdx,
        officeIdx,
        siteHostLimit,
        noAuthorCheck: true
      },
    };

    _API.post(req).then((res) => {
      const { data } = res?.data;
      setManagers(data);
    });
  }

  /**
    * 그룹, 섹션, 입력항목 리스트
    */
  const loadInputList = async () => {
    const req = {
      path: "/v1/campaign/input/list",
      data: {
        campaignIdx: campaignIdx,
        noAuthorCheck: true
      },
    };

    await _API.post(req).then((res) => {
      const { defaultInputItems, additionalInputItems } = res.data;
      setInputItems([...defaultInputItems, ...additionalInputItems]);

      const managerInput = defaultInputItems[0]?.children?.find((input) => input.fieldName == 'MANAGER' && ['MANAGER_SELECT', 'MANAGER_TEXT'].includes(input.inputType));

      if (managerInput?.isTeamUse) loadTeamList(managerInput?.idx);
      if (managerInput?.isOfficeUse) loadOfficeList(managerInput?.idx);

    });
  }

  /**
   * 이용약관 전체선택 & 전체선택해제
   * @param {*} checked true 또는 false
   * @param {*} type on 또는 off
   */

  const onTermsAllChecked = (checked) => {

    setRegFormInfo((o) => {
      return { ...o, [`termsAllChecked`]: checked }
    });

    setInputItems((terms) => {
      let newTermsList = terms;

      let defaultInputItems = newTermsList?.filter((e) => e.isTermsGroup != 'Y');
      let termsInputItems = newTermsList?.filter((e) => e.isTermsGroup == 'Y');

      let updatedTermsInputItems = JSON.parse(JSON.stringify(termsInputItems)).map((item) => {
        item.children = item.children.map((child) => {

          if(child?.inputType == 'TERMS') {
            child.checked = checked;
          }

          if (child?.opts) {
            child.opts = child.opts.map((item) => {
              item.checked = checked;
              return item; 
            });
            child.checked = checked;
          }

          return child;
        });
        return item;
      });

      return [...defaultInputItems, ...updatedTermsInputItems];
    });

  }

  /**
   * 이벤트 리스트 전체선택 & 전체선택해제
   * @param {*} checked true 또는 false
   * @param {*} type on 또는 off
   */
  const onAllChecked = (checked, type) => {

    let onCount = eventList?.filter((e) => e.onlineChecked == true).length;
    let offCount = eventList?.filter((e) => e.offlineChecked == true).length;

    if (onCount == 0 || offCount == 0) {

      setRegFormInfo((o) => {
        return { ...o, [`${type}lineAllChecked`]: checked }
      });

      setEventList((events) => {
        let newEvents = events;
        newEvents?.map((event) => {

          if (['onoff', type].includes(event?.progressTarget)) {
            if (type == 'off') {
              if (event.status == 'Y') {
                event.offlineChecked = checked; // 오프라인 이벤트 모두 선택 또는 모두 선택해제
              }

            }
            else if (type == 'on') {
              if (event.status == 'Y') {
                event.onlineChecked = checked; // 온라인 이벤트 모두 선택 또는 모두 선택해제 
              }
            }
          }
        })
        return newEvents;
      })
    }

    if (campaignInfo?.typeSingle == 'Y') {

      let onCount = eventList?.filter((e) => e.onlineChecked == true).length;
      let offCount = eventList?.filter((e) => e.offlineChecked == true).length;

      if (onCount > 0 && type == 'off') {
        if (!confirm(`주의: 오프라인 전체 선택 시 기존에 선택한 온라인 참가가 모두 해제됩니다. 계속 진행하시겠습니까?`)) {
          setEventList((events) => {
            let newEvents = events;
            newEvents?.map((event) => {
              if (['onoff', type].includes(event?.progressTarget)) {
                if (type == 'off') {
                  if (event.status == 'Y') {
                    event.offlineChecked = false;
                  }
                }
              }
            })
            return newEvents;
          })
          setRegFormInfo((o) => {
            return { ...o, [`${type}lineAllChecked`]: !checked }
          });
          return;
        }
        setRegFormInfo((o) => {
          return { ...o, [`${type}lineAllChecked`]: checked, onlineAllChecked: !checked }
        });

        setEventList((events) => {
          let newEvents = events;
          newEvents?.map((event) => {
            if (['onoff', type].includes(event?.progressTarget)) {
              if (type == 'off') {
                if (event.status == 'Y') {
                  event.offlineChecked = true;
                  event.onlineChecked = false;
                }
              } 
            }
            event.onlineChecked = false;
          })
          return newEvents;
        })
      } else if(offCount > 0 && type == 'on') {

        if (!confirm(`주의: 온라인 전체 선택 시 기존에 선택한 오프라인 참가가 모두 해제됩니다. 계속 진행하시겠습니까?`)) {
          setEventList((events) => {
            let newEvents = events;
            newEvents?.map((event) => {
              if (['onoff', type].includes(event?.progressTarget)) {
                if (type == 'on') {
                  if (event.status == 'Y') {
                    event.onlineChecked = false;
                  }
                }
              }
            })
            return newEvents;
          })
          setRegFormInfo((o) => {
            return { ...o, [`${type}lineAllChecked`]: !checked }
          });
          return;
        }
        setRegFormInfo((o) => {
          return { ...o, [`${type}lineAllChecked`]: checked, offlineAllChecked: !checked }
        });

        setEventList((events) => {
          let newEvents = events;
          newEvents?.map((event) => {
            if (['onoff', type].includes(event?.progressTarget)) {
               if (type == 'on') {
                if (event.status == 'Y') {
                  event.onlineChecked = checked;
                  event.offlineChecked = false;
                }
              } 
            } 
            event.offlineChecked = false;
          })
          return newEvents;
        })

      }
    } else {
      setRegFormInfo((o) => {
        return { ...o, [`${type}lineAllChecked`]: checked }
      });
      setEventList((events) => {
        let newEvents = events;
        newEvents?.map((event) => {

          if (['onoff', type].includes(event?.progressTarget)) {
            if (type == 'off') {
              if (event.status == 'Y') {
                event.offlineChecked = checked; // 오프라인 이벤트 모두 선택 또는 모두 선택해제
              }

            }
            else if (type == 'on') {
              if (event.status == 'Y') {
                event.onlineChecked = checked; // 온라인 이벤트 모두 선택 또는 모두 선택해제 
              }
            }
          }
        })
        return newEvents;
      })
    }

  }

  /**
 * 섹션, 입력항목 Component
 * @param {*} props {item, onDataChange}
 * @returns 
 */
  const ChildInput = (props) => {

    const { item, isTermsGroup } = props;

    const [editInput, setEditInput] = useState({});
    const [errorInput, setErrorInput] = useState({});

   /*   useEffect(() => {
      console.log('editInput =', editInput);
    }, [errorInput, editInput])  */

    return (
      <Stack direction="column" sx={{ gap: '24px' }}>
        {/* 타입이 섹션이거나 입력항목 중 포함여부가 Y인 항목 */}
        {item?.children?.filter((child) =>
          child?.inputType == 'SECTION'
          ||
          child?.isAlwaysShow
          || (
            child?.useYn == 'Y'
            && child?.onlyHost != 'Y'
            && (
              child?.defaultYn == 'Y'
              || (eventList?.filter((e) => e.offlineChecked)?.length > 0 && ['OFF', 'ONOFF'].includes(child?.onOffUse))
              || (eventList?.filter((e) => e.onlineChecked)?.length > 0 && ['ON', 'ONOFF'].includes(child?.onOffUse))
            )
          )
        )?.map((child, i) => {
          return (
            <>
              {child?.inputType == 'SECTION' ? (
                <Stack className="section_box" key={`${child?.idx}_${i}`} sx={{ gap: "24px", margin: i == item?.children?.length - 1 ? '0px' : '24px 0' }}>
                  <Stack sx={{ gap: "8px" }}>
                    <Typography component="h5" variant="h7" sx={{ display: 'flex', justifyContent: "space-between" }}>
                      {child?.name}
                    </Typography>
                    {child?.description && (
                      <Typography component="p" variant="p" sx={{ paddingBottom: "8px", marginBottom: "24px" }}>{child?.description}</Typography>
                    )}
                  </Stack>
                  {child?.children.length > 0 && (
                    <ChildInput item={child} />
                  )}
                </Stack>
              ) : (
                <Box>
                    {(child?.fieldName == 'MANAGER' && ['MANAGER_SELECT','MANAGER_TEXT'].includes(child?.inputType)) ? (<>
                      {child?.isTeamUse ? (
                        <MuiSelects
                          title={child?.name}
                          required={child?.requiredYn == 'Y'}
                          name={`input_${child?.idx}`}
                          defaultValue={child?.mgrTeamIdx ?? ''}
                          items={[{ label: '팀 선택', value: '' }, ...teams]}
                          onChange={(e) => {
                            handleChange(child?.idx, e?.target?.value, 'mgrTeamIdx');
                            loadMgrList({ inputIdx: child?.idx, teamIdx: e?.target?.value, officeIdx: child?.mgrOfficeIdx ? child?.mgrOfficeIdx : '', siteHostLimit: child?.siteHostLimit });
                          }}
                        > 
                          {child?.isOfficeUse ? (
                            <MuiSelects
                              required={child?.isTeamUse ? false : child?.requiredYn == 'Y'}
                              name={`input_${child?.idx}`}
                              defaultValue={child?.mgrOfficeIdx ?? ''}
                              items={[{ label: '영업소 선택', value: '' }, ...offices]}
                              onChange={(e) => {
                                  handleChange(child?.idx, e?.target?.value, 'mgrOfficeIdx');
                                  loadMgrList({ inputIdx: child?.idx, teamIdx: child?.mgrTeamIdx ? child?.mgrTeamIdx : '', officeIdx: e?.target?.value, siteHostLimit: child?.siteHostLimit });
                              }}
                            />
                          ) : null}
                        {/*     <MuiSelects
                            required={(child?.isTeamUse || child?.isOfficeUse) ? false : child?.requiredYn == 'Y'}
                            name={`input_${child?.idx}`}
                            defaultValue={child?.value ?? ''}
                            items={[{ label: '담당자 선택', value: '' }, ...managers]}
                            maxTextWidth={300}
                            onChange={(e) => {
                              handleChange(child?.idx, e?.target?.value);
                            }}
                            hint={child?.description}
                          /> */}

                        {child?.inputType == 'MANAGER_SELECT' ? (
                          <MuiSelects
                            required={(child?.isTeamUse || child?.isOfficeUse) ? false : child?.requiredYn == 'Y'}
                            name={`input_${child?.idx}`}
                            defaultValue={child?.value ?? ''}
                            items={[{ label: '담당자 선택', value: '' }, ...managers]}
                            onChange={(e) => {
                              handleChange(child?.idx, e?.target?.value);
                            }}
                            hint={child?.description}
                          />
                        ) : (
                          <Input
                            key={`${child?.idx}_${i}`}
                            name={`${child?.idx}_${i}`}
                            value={editInput[child?.idx] ?? child?.value}
                            onChange={async (e) => {
                              setEditInput((o) => { return { ...o, [child?.idx]: e.target.value } })
                              handleChange(child?.idx, e.target.value);
                            }}
                            placeholder={child?.placeholder}
                            marginBtm="4px"
                            width={fullScreen && '100%'}
                            mobile={mobile}
                            hint={child?.description}
                          />
                        )}

                      </MuiSelects>

                    ) : null}


                  </>) : ['TEXT', 'EMAIL', 'MOBILE'].includes(child?.inputType) ? (
                    <Input
                      key={`${child?.idx}_${i}`}
                      name={`${child?.idx}_${i}`}
                      title={child?.name}
                      required={child?.requiredYn == 'Y'}
                      value={editInput[child?.idx] ?? child?.value}
                      onChange={async (e) => {
                        setEditInput((o) => ({ ...o, [child?.idx]: e.target.value }));
                        handleChange(child?.idx, e.target.value);
                      }}
                      onBlur={async (e) => {
                        let value = e.target.value;
                        if (child?.inputType === 'MOBILE') {
                          value = _U.isModPhoneNumber(e.target.value);
                          setEditInput((o) => ({ ...o, [child?.idx]: value }));
                          handleChange(child?.idx, value);
                        }

                        if (child?.isUnique === 'Y' && (editInput[child?.idx] ?? child?.value)) {

                          await onUniqValueCheck(value, child).then((res) => {
                            if (res) {
                              setErrorInput((o) => { return { ...o, [child?.idx]: `이미 사용중인 ${child?.name}입니다.` } })
                              handleChange(child?.idx, `이미 사용중인 ${child?.name}입니다.`, 'errMsg');
                              handleChange(child?.idx, true, 'isDuplicate');
                            } else {
                              setErrorInput((o) => { return { ...o, [child?.idx]: `` } })
                              handleChange(child?.idx, ``, 'errMsg');
                              handleChange(child?.idx, false, 'isDuplicate');
                            }
                          });
                        }
                      }}
                      placeholder={child?.placeholder}
                      errorMsg={errorInput[child?.idx] ?? child?.errMsg}
                      hint={child?.description}
                      width={fullScreen && '100%'}
                      mobile={mobile}
                    />
                  ) : child?.inputType == 'TEXTAREA' ? (
                    <TextArea
                      key={`${child?.idx}_${i}`}
                      title={child?.name}
                      required={child?.requiredYn == 'Y'}
                      placeholder={child?.placeholder}
                      value={editInput[child?.idx] ?? child?.value}
                      onChange={(e) => {
                        setEditInput((o) => { return { ...o, [child?.idx]: e.target.value } })
                        handleChange(child?.idx, e.target.value);
                      }}
                      /* onBlur={(e) => {
                        handleChange(child?.idx, editInput[child?.idx] ?? child?.value);
                      }} */
                      hint={child?.description}
                      limit={1000}
                      onFocus={(e) => console.log(e)}
                      width={fullScreen && '100%'}
                      mobile={mobile}
                    />
                  ) : child?.inputType == 'CHECKBOX' ? (
                    <>
                      {isTermsGroup == 'Y' ? (
                        <Stack direction={`${!mobile ? "row" : "column"}`} alignItems="flex-start" sx={{ rowGap: "8px", columnGap: "16px" }}>
                          <Typography component="h4" variant="p" sx={{ minWidth: `${!mobile ? "160px" : "100%"}`, width: `${!mobile ? "160px" : "100%"}`, padding: `${!mobile ? "9px 0px 9px 0" : "0"}` }}>
                            {child?.name} {child?.requiredYn == 'Y' ? <img src={requiredImg} style={{ marginTop: "4px", marginLeft: "1px", verticalAlign: "top" }} /> : ""}
                          </Typography>
                          <Box sx={{ width: "100%" }}>
                            {child?.medicalTagUse == 'Y' && (
                              <div style={{ display: "block", marginLeft: "auto", border: "1px solid #eaeaea", padding: "2px 4px", margin: "8px 0 8px auto", height: "22px", lineHeight: "14px", width: 'fit-content' }}>
                                <Typography component="small" variant="summary" sx={{ lineHeight: "15px" }}>보건의료전문가</Typography>
                              </div>
                            )}
                            <Box>
                            <Box sx={{ padding: "12px", background: "#F7F7FA", borderRadius: "4px", margin: "0px 0 8px auto", height: "130px", overflowY: "auto", color: "#666666" }}>
                                <div dangerouslySetInnerHTML={{ __html: child?.termsContents }} />
                            </Box>
                              <Checkbox
                                items={child?.opts}
                                onChange={(e) => {
                                  child?.opts?.map((opt) => {
                                    if (opt?.idx == e.target.value) {
                                      opt.checked = e?.target?.checked;
                                      setEditInput((o) => { return { ...o, [child?.idx]: e.target.value } });
                                      handleChange(child?.idx, e.target.value, 'checked');

                                    } else if (opt?.idx != e.target.value && child?.opts?.find((o) => o?.idx == e.target.value)?.isAllCheck) {
                                      opt.checked = e?.target?.checked;
                                      setEditInput((o) => { return { ...o, [child?.idx]: e.target.value } });
                                      handleChange(child?.idx, e.target.value, 'checked');
                                    } else if (opt?.idx != e.target.value && opt?.isAllCheck) {
                                      let copyOpts = child?.opts;
                                      for (let i = 0; i < copyOpts?.length; i++) {
                                        if (copyOpts[i]?.idx == e.target.value) {
                                          copyOpts[i].checked = e.target.checked;
                                        }
                                      }
                                      let allCheck = child?.opts?.filter((o) => !o?.isAllCheck && Boolean(o?.checked))?.length == child?.opts?.filter((o) => !o?.isAllCheck)?.length;
                                      opt.checked = allCheck;
                                      setEditInput((o) => { return { ...o, [child?.idx]: e.target.value } });
                                      handleChange(child?.idx, e.target.value, 'checked');
                                    }

                                  })
                                }}
                                hint={child?.description}
                              />
                            </Box>
                          </Box>
                        </Stack>
                      ) : (
                        <Checkbox
                          title={child?.name}
                          required={child?.requiredYn == 'Y'}
                          items={child?.opts}
                          onChange={(e) => {
                            child?.opts?.map((opt) => {
                              if (opt?.idx == e.target.value) {
                                opt.checked = e?.target?.checked;
                                setEditInput((o) => { return { ...o, [child?.idx]: e.target.value } });

                              } else if (opt?.idx != e.target.value && child?.opts?.find((o) => o?.idx == e.target.value)?.isAllCheck) {
                                opt.checked = e?.target?.checked;
                                setEditInput((o) => { return { ...o, [child?.idx]: e.target.value } });

                              } else if (opt?.idx != e.target.value && opt?.isAllCheck) {
                                let copyOpts = child?.opts;
                                for (let i = 0; i < copyOpts?.length; i++) {
                                  if (copyOpts[i]?.idx == e.target.value) {
                                    copyOpts[i].checked = e.target.checked;
                                  }
                                }
                                let allCheck = child?.opts?.filter((o) => !o?.isAllCheck && Boolean(o?.checked))?.length == child?.opts?.filter((o) => !o?.isAllCheck)?.length;
                                opt.checked = allCheck;
                                setEditInput((o) => { return { ...o, [child?.idx]: e.target.value } });
                              }

                            })
                          }}
                          hint={child?.description}
                          mobile={mobile}
                        />
                      )}
                    </>
                  ) : child?.inputType == 'RADIO' ? (
                    <Radio
                      title={child?.name}
                      required={child?.requiredYn == 'Y'}
                      items={child?.opts}
                      name={`input_${child?.idx}`}
                      value={child?.opts?.find((o) => o.checked == true)?.value}
                      onChange={(e) => {
                        child?.opts?.map((opt) => {
                          if (opt?.value == e.target.value) opt.checked = e?.target?.checked;
                          else opt.checked = false;
                        })
                      }}
                      hint={child?.description}
                      mobile={mobile}
                    />
                  ) : ['MANAGER_SELECT', 'SELECT'].includes(child?.inputType) ? (
                    <MuiSelects
                      title={child?.name}
                      required={child?.requiredYn == 'Y'}
                      name={`input_${child?.idx}`}
                      defaultValue={child?.value ?? ''}
                      items={[{ label: '선택', value: '' }, ...child?.opts]}
                      onChange={(e) => {
                        handleChange(child?.idx, e?.target?.value);
                      }}
                      hint={child?.description}
                      mobile={mobile}
                    />
                  ) : child?.inputType == 'HOSPITAL' ? (
                    <>
                      <Input
                        title={child?.name}
                        required={child?.requiredYn == 'Y'}
                        value={child?.value}
                        onClick={() => openDialog(child?.idx)}
                        placeholder={child?.placeholder}
                        search
                        hint={child?.description}
                        width={fullScreen && '100%'}
                        mobile={mobile}
                      />
                      {(choiceHospitalInputIdx == child?.idx && hospitalOpen == true) && (
                        <HospitalFindDialog
                          open={hospitalOpen}
                          setOpen={setHospitalOpen}
                          closeAfter={(hName, hospitalAddr) => {
                            handleChange(child?.idx, !hName && child?.value ? child?.value : hName);
                            handleChange(child?.idx, hospitalAddr ,'hospitalAddr');
                          }}
                        />
                      )}
                    </>
                  ) : child?.inputType == 'TERMS' ? (
                    <Stack direction={`${!mobile ? "row" : "column"}`} alignItems="flex-start" sx={{ rowGap: "8px", columnGap: "16px" }}>
                      <Typography component="h4" variant="h4" sx={{ minWidth: `${!mobile ? "160px" : "100%"}`, width: `${!mobile ? "160px" : "100%"}`, padding: `${!mobile ? "9px 0px 9px 0" : "0"}` }}>
                        {child?.name} {child?.requiredYn == 'Y' ? <img src={requiredImg} style={{ marginTop: "4px", marginLeft: "1px", verticalAlign: "top" }} /> : ""}
                      </Typography>
                      <Box sx={{ width: "100%" }}>
                        {child?.medicalTagUse == 'Y' && (
                          <div style={{ display: "block", marginLeft: "auto", border: "1px solid #eaeaea", padding: "2px 4px", margin: "8px 0 8px auto", height: "22px", lineHeight: "14px", width: 'fit-content' }}>
                            <Typography component="small" variant="summary" sx={{ lineHeight: "15px" }}>보건의료전문가</Typography>
                          </div>
                        )}
                        <Box>
                        <Box sx={{ padding: "12px", background: "#F7F7FA", borderRadius: "4px", margin: "0px 0 8px auto", height: "130px", overflowY: "auto",color:"#666"}}>
                            <div dangerouslySetInnerHTML={{ __html: child?.termsContents }} />
                        </Box>
                          <Typography component="p" variant="p" sx={{ marginBottom: "4px" }}>{child?.termsQuestion}</Typography>
                          <Checkbox
                            items={[{ label: child?.termsAgree, value: 'Y', checked: child?.checked }]}
                            onChange={(e) => {
                              setEditInput((o) => { return { ...o, [child?.idx]: e.target.value } })
                              handleChange(child?.idx, e?.target?.checked, 'checked');
                            }}
                          />
                        </Box>
                      </Box>
                    </Stack>
                  ) : (
                    <></>
                  )}
                </Box>
              )}
            </>
          )
        })}
      </Stack>

    )
  }

  // 각 역할별로 매니저 목록 출력
  const RenderManagers = (props) => {

    const { title, list } = props;

    return (
      <>
        {list?.length > 0 && (
          <div style={{ display: "flex", alignItems: "flex-start" }}>
            <Typography component="small" variant="subtext6" sx={{ minWidth: "33px",padding:"1px 0" }}>
              {title}
            </Typography>
            {list?.length > 0 && (
              <div style={{ display: "flex", flexDirection: "column", gap: "2px" }}>
                {list?.map((item, i) => {
                  return (
                    <Typography key={i} component="span" variant="span" sx={{ display: "block", padding:"1px 0"}}>
                      {item.name} ({item?.companyName}) <br></br>
                    </Typography>
                  )
                }
                )}
              </div>
            )}
          </div>
        )}
      </>
    );
  };

  /**
   * 이벤트 리스트 단일 선택 & 선택해제
   * @param {*} checked true 또는 false
   * @param {*} idx 이벤트 idx
   * @param {*} type on 또는 off
   */
  const onChecked = (checked, idx, type) => {

    let newEvents = eventList?.map((data) => {
      return { ...data, [`${type}lineChecked`]: data?.idx == idx ? checked : data?.[`${type}lineChecked`] }
    })

    let onCount = eventList?.filter((e) => e.onlineChecked == true).length;
    let offCount = eventList?.filter((e) => e.offlineChecked == true).length;

    if (campaignInfo?.typeSingle == 'Y') {

      if (onCount > 0 && type == 'off') {
        if (!confirm(`온라인 또는 오프라인 중 하나만 선택 가능합니다. 오프라인 참가로 변경하시겠습니까?`)) {
          newEvents = eventList?.map((data) => {
            return { ...data, [`${type}lineChecked`]: data?.idx == idx ? !checked : data?.[`${type}lineChecked`] }
          })
          return setEventList(newEvents)
        }
        newEvents = eventList?.map((data) => {
          return { ...data, [`${type}lineChecked`]: data?.idx == idx ? checked : data?.[`${type}lineChecked`], [`onlineChecked`]: data?.idx == idx ? !checked : data?.[`${type}lineChecked`] }
        })
      } else if (offCount > 0 && type == 'on') {
        if (!confirm(`온라인 또는 오프라인 중 하나만 선택 가능합니다. 온라인 참가로 변경하시겠습니까?`)) {
          newEvents = eventList?.map((data) => {
            return { ...data, [`${type}lineChecked`]: data?.idx == idx ? !checked : data?.[`${type}lineChecked`] }
          })
          return setEventList(newEvents)
        }
        newEvents = eventList?.map((data) => {
          return { ...data, [`${type}lineChecked`]: data?.idx == idx ? checked : !checked, [`offlineChecked`]: data?.idx == idx ? false : data?.[`${type}lineChecked`] }
        })
      }
      // 온/오프라인에 따라 선택 시 모두 체크 되었는지 확인 후 전체선택 chechbox에도 true 또는 false 적용
      if (type == 'off') {
        let offlineCount = newEvents?.filter((e) => ['onoff', 'off'].includes(e.progressTarget))?.length;
        let offlineCheckCont = newEvents?.filter((e) => e.offlineChecked == true)?.length;
        setRegFormInfo((o) => {
          return { ...o, offlineAllChecked: offlineCount == offlineCheckCont ? true : false, onlineAllChecked: false }
        })
      } else if (type == 'on') {
        let onlineCount = newEvents?.filter((e) => ['onoff', 'on'].includes(e.progressTarget))?.length;
        let onlineCheckCont = newEvents?.filter((e) => e.onlineChecked == true)?.length;
        setRegFormInfo((o) => {
          return { ...o, onlineAllChecked: onlineCount == onlineCheckCont ? true : false, offlineAllChecked : false }
        })
      }
      setEventList(newEvents);
    } else {
      // 온/오프라인에 따라 선택 시 모두 체크 되었는지 확인 후 전체선택 chechbox에도 true 또는 false 적용
      if (type == 'off') {
        let offlineCount = newEvents?.filter((e) => ['onoff', 'off'].includes(e.progressTarget))?.length;
        let offlineCheckCont = newEvents?.filter((e) => e.offlineChecked == true)?.length;
        setRegFormInfo((o) => {
          return { ...o, offlineAllChecked: offlineCount == offlineCheckCont ? true : false }
        })
      } else if (type == 'on') {
        let onlineCount = newEvents?.filter((e) => ['onoff', 'on'].includes(e.progressTarget))?.length;
        let onlineCheckCont = newEvents?.filter((e) => e.onlineChecked == true)?.length;
        setRegFormInfo((o) => {
          return { ...o, onlineAllChecked: onlineCount == onlineCheckCont ? true : false }
        })
      }
      setEventList(newEvents);

    }

  }

  const findItemByIndex = (array, targetIdx, parent = null) => {
    for (let i = 0; i < array.length; i++) {
      if (array[i].idx == targetIdx) {
        return { item: array[i], parent: parent };
      }
      else if (array[i]?.children) {
        const result = findItemByIndex(array[i].children, targetIdx, array[i]);
        if (result) {
          return result;
        }
      }
    }
    return null;
  }

  /**
   * 입력항목 입력
   * @param {*} newData 
   */

  const handleChange = (idx, value, key = 'value') => {
    const { item } = findItemByIndex(inputItems, idx);
    
    Object.assign(item, { ...item, [key]: value });

    if(key == 'checked') {
      updateTermsAllCheckedState(inputItems);
    }

  };

  const updateTermsAllCheckedState = (items) => {

    const termsGroup = items?.find(item => item.isTermsGroup === 'Y');

    const termsGroupOpts = termsGroup.children.map((child) => {
        if (child?.opts) {
            child.checked = child.opts.every(opt => opt.checked);
        }
        return child; 
    });

    if (!termsGroup) return;

    const allChecked = termsGroupOpts.every(child => child.checked);

    setRegFormInfo(prev => ({ ...prev, termsAllChecked: allChecked }));
};

  /* const updateInputItems = (items, inputIdx, newValue, key) => {
    return items.map((item) => {
      if (item.idx === inputIdx) {
        // 해당 노드를 업데이트
        return { ...item, [key]: newValue };
      } else if (item.children) {
        // 자식 노드 업데이트
        return { ...item, children: updateInputItems(item.children, inputIdx, newValue, key) };
      } else {
        return item;
      }
    });
  }; */

  const onValidationCheck = (data) => {

    let isValid = true;

    // 데이터의 반복을 중단할 플래그
    outerLoop: for (const item of data) {

      if (item.children?.length > 0
        &&
        item.isTermsGroup == 'Y'
        ||
        (
          item?.defaultYn == 'Y'
          || (eventList?.filter((e) => e.offlineChecked)?.length > 0 && ['OFF', 'ONOFF'].includes(item?.onOffUse))
          || (eventList?.filter((e) => e.onlineChecked)?.length > 0 && ['ON', 'ONOFF'].includes(item?.onOffUse))
        )) {

        for (const child of item.children) {

          if (child?.isUnique == 'Y') {
            if (child?.isDuplicate) {
              alert(`${child.name}의 중복 등록으로 인해 등록 진행이 불가 합니다.\n메세지를 확인하시고 등록 내용을 수정하시기 바랍니다.\n중복 등록과 관련하여 문의사항이 있으시면 사무국으로 문의해 주시기 바랍니다.`);
              isValid = false;
              break outerLoop;
            }
          }

          if (child.onlyHost != 'Y') {

            if (child?.fieldName == 'MANAGER' && ['MANAGER_SELECT', 'MANAGER_TEXT'].includes(child?.inputType) && child.requiredYn == 'Y') {

              if (child?.isTeamUse && child?.isOfficeUse) {
                if (!child?.mgrTeamIdx && !child?.mgrOfficeIdx) {
                  alert(`팀 또는 영업소를 선택해 주세요.`);
                  isValid = false;
                  break outerLoop;
                }
              }

              if (child?.isTeamUse && !child?.isOfficeUse && child.requiredYn == 'Y') {
                if (!child?.mgrTeamIdx) {
                  alert(`팀을 선택해 주세요.`);
                  isValid = false;
                  break outerLoop;
                }
              }

              if (!child?.isTeamUse && child?.isOfficeUse && child.requiredYn == 'Y') {
                if (!child?.mgrOfficeIdx) {
                  alert(`영업소를 선택해 주세요.`);
                  isValid = false;
                  break outerLoop;
                }
              }

              if (!child.value) {
                alert(`담당자를 선택해 주세요.`);
                isValid = false;
                break outerLoop;
              }

            }

            /*  if (!['TERMS'].includes(child?.inputType) && child.requiredYn == 'Y' && !child.value) {
               alert(`'${child.name}' 항목은 필수값입니다.`);
               isValid = false;
               break outerLoop;
             } */

            if (['TEXT', 'EMAIL', 'MOBILE', 'TEXTAREA', 'SELECT', 'HOSPITAL'].includes(child?.inputType) && child.requiredYn == 'Y' && !child.value) {
              alert(`'${child.name}' 항목은 필수값입니다.`);
              isValid = false;
              break outerLoop;
            }

            if (['CHECKBOX', 'RADIO'].includes(child?.inputType)) {
              if (child.requiredYn == 'Y' && child?.opts.filter((item) => item.checked == true).length < 1) {
                alert(`'${child.name}' 항목은 필수값입니다.`);
                isValid = false;
                break outerLoop;
              }
            }

            if (child?.isAlwaysShow || (eventList?.filter((e) => e.offlineChecked)?.length > 0 && ['OFF', 'ONOFF'].includes(child?.onOffUse))
              || (eventList?.filter((e) => e.onlineChecked)?.length > 0 && ['ON', 'ONOFF'].includes(child?.onOffUse))) {

              if (['TERMS'].includes(child?.inputType) && child.requiredYn == 'Y' && !child.checked) {
                alert(`'${child.name}' 항목은 필수값입니다.`);
                isValid = false;
                break outerLoop;
              }

            }

            if (child.value && child.inputType == 'EMAIL' && child.useYn == 'Y') {
              if (!_U.isEmail(child.value)) {
                alert("이메일 형식에 맞지 않습니다.");
                isValid = false;
                break outerLoop;
              }
            }

            if (child.value && child.inputType == 'MOBILE' && child.useYn == 'Y') {
              if (!_U.isMobilePhoneNumber(child.value)) {
                alert("모바일 형식에 맞지 않습니다.");
                isValid = false;
                break outerLoop;
              }
            }

            if (child.children) {
              for (const i of child.children) {

                if (i.onlyHost != 'Y') {

                  if (!['CHECKBOX', 'RADIO'].includes(i?.inputType) && i.requiredYn == 'Y' && !i.value) {
                    alert(`'${i.name}' 항목은 필수값입니다.`);
                    isValid = false;
                    break outerLoop;
                  }

                  if (['CHECKBOX', 'RADIO'].includes(i?.inputType)) {
                    if (i.requiredYn == 'Y' && i?.opts.filter((item) => item.checked == true).length < 1) {
                      alert(`'${i.name}' 항목은 필수값입니다.`);
                      isValid = false;
                      break outerLoop;
                    }
                  }

                }

              }
            }

          }

        }
      }
    }

    return isValid;
  }

  /**
   * 병원명 검색 open
   * @param {*} idx 입력항목 idx
   */

  const openReg = () => {

    let offLen = eventList?.filter((e) => e.offlineChecked)?.length
    let onLen = eventList?.filter((e) => e.onlineChecked)?.length

    let mgrNameIdx = inputItems[0]?.children?.find((input) => input.fieldName == 'MANAGER' && input.inputType == 'MANAGER_SELECT')?.value;

    const name = managers.filter((e) => e.idx == mgrNameIdx)[0]?.name;

    setMgrName(name);

    if (['EVENT', 'SESSION'].includes(campaignInfo?.progressStandard)) {
      const msg = campaignInfo.progressStandard === 'EVENT'
        ? '진행기준이 이벤트인 경우 하나이상의 이벤트를 선택해야 합니다.'
        : campaignInfo.progressStandard === 'SESSION' ? '진행기준이 세션인 경우 하나이상의 세션을 선택해야 합니다.' : '';

      if (offLen <= 0 && onLen <= 0) {
        alert(msg);
        return false;
      }
    }

    if (!onValidationCheck(inputItems)) return false;

    let gubun = 'pre';

    if (gubun == "pre") {
      setPreregOpen(true);
    } else if (gubun == "alert") {
      setAlertOpen(true);
    }

  }

  const openDialog = (idx) => {
    setChoiceHospitalInputIdx(idx);
    setHospitalOpen(true);
  }

  const onUniqValueCheck = async (value, data) => {

    const { fieldName, idx } = data;

    const req = {
      path: "/v1/campaign/reg/single/unique/check",
      data: {
        campaignIdx: campaignIdx,
        fieldName: fieldName,
        inputIdx: idx,
        value: value,
        noAuthorCheck: true
      },
    };

    return await _API.post(req).then((res) => {
      const { data } = res.data;

      return data?.length > 0;
    });

  }

  const loadCampaignRegForm = () => {

    const req = {
      path: "/v1/campaign/reg_form/info",
      data: {
        campaignIdx: campaignIdx,
        noAuthorCheck: true
      },
    };

    _API.post(req).then((res) => {
      const { data } = res.data;

      console.log('regForm =', data);

      setRegFormInfo(data);

      setRegFormInfo((o) => {
        return {
          ...o,
          [`introMoreViewYn`]:
            showIntroRef?.current?.scrollHeight > 67 ? `Y` : `N`,
        }
      })
    });
  };

  const onCampaignCheck = async () => {
    const req = {
      path: "/v1/campaign/site/check",
      data: {
        campaignIdx: campaignIdx,
      },
    };

    try {
      const res = await _API.post(req);
      const { data } = res.data;
      let msg = '';

      if (data) {
        if(data.status == 'W') {
          msg = `캠페인을 준비 중입니다.`;
        } else if(data.status == 'N') {
          msg = data.endMsg;
        }
        alert(msg);
        window.location.href = location.origin + '/';
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.error('Error during campaign check:', error);
    }
  };

  const loadbrandingInfo = () => {

    const req = {
      path: "/v1/campaign/branding/info",
      data: {
        campaignIdx: campaignIdx,
        templateType: 'CAMPAIGN',
        noAuthorCheck: true
      },
    };

    _API.post(req).then((res) => {

      const { brandingInfo, templateInfo, defaultTemplateInfo } = res.data;

      setBrandingInfo(brandingInfo);
      setTemplateInfo(templateInfo);
      setDefaultTemplateInfo(defaultTemplateInfo);

    });
  };

  const loadCampaignInfo = async () => {

    const req = {
      path: "/v1/campaign/info",
      data: {
        idx: campaignIdx,
        noAuthorCheck: true,
        eventStatus : true,
        sessionStatus : true
      },
    };

     await _API.post(req).then((res) => {
      const { data, event, session } = res.data;

      if (event?.length > 0) {
        for (let i in event) {
          const result = event[i];

          result['seatManagers'] = [];
          result['speakerManagers'] = [];
          result['panelManagers'] = [];

          result?.managerList.forEach(item => {
            if (item?.mgrType == "SEAT") {
              result['seatManagers'].push(item);
            } else if (item?.mgrType == "SPEAKER") {
              result['speakerManagers'].push(item);
            } else {
              result['panelManagers'].push(item);
            }
          });
        }
        setEventList(event);
      } else if(session?.length > 0) {
        setEventList(session);
      }

      setCampaignInfo(data);

    });
  };

  /*   useEffect(() => {
  
      if(heightCheck == 'Y') {
        loadCampaignRegForm();
      }
  
    },[heightCheck])
  
    useEffect(() => {
  
      if(showIntroRef?.current?.scrollHeight >= 0) {
        setHeightCheck('Y')
      }
  
    }, [showIntroRef?.current?.scrollHeight]) */

  /*   useEffect(() => {
        onCampaignCheck();
        loadbrandingInfo();
        loadCampaignInfo();
        if(campaignInfo?.idx) {
          loadCampaignRegForm();
          loadInputList();
        }
    }, [campaignInfo?.idx]); */

  useEffect(() => {
    const initialize = async () => {
      await onCampaignCheck();
      loadbrandingInfo();
      await loadCampaignInfo();
      loadCampaignRegForm();
      loadInputList();
    };

    initialize();
  }, []);
  if (loading) {
    return (
      <>
      </>
    )
  } else {
    return (
      <ThemeProvider theme={theme}>
        {brandingInfo?.logoImgUrl ? (<Header logoImgUrl={brandingInfo?.logoImgUrl} mobile={mobile} />) : ''}
        <Container>
          <section id="section" className="wid_1180">
            {brandingInfo?.mainImgUrl && (
              <div className="slide_box">
                <Swiper
                  observer={true}
                  observeParents={true}
                  // pagination={{
                  //   clickable: false,
                  // }}
                  loop={false}
                  // autoplay={{
                  //   delay: 2000,
                  //   disableOnInteraction: false
                  // }}
                  modules={[Autoplay, Pagination, Navigation]}
                  className="swiper_container"
                >
                  <SwiperSlide>
                    {brandingInfo?.mainImgUrl && (
                      <img src={brandingInfo?.mainImgUrl} alt="" style={{ display: "block", width: "100%" }} />
                    )}
                  </SwiperSlide>
                </Swiper>
              </div>
            )}
            <div className="content">
              <div className="cont_left">
                <div className="cont_left_inner cont_left_inner1">
                  <Typography component="h2" variant="h2">{campaignInfo?.name}</Typography>
                  {campaignInfo?.introUse == 'Y' && (
                    <Typography component="p" variant="p">
                      <div dangerouslySetInnerHTML={{ __html: campaignInfo?.intro }} />
                    </Typography>
                  )}
                </div>
                {campaignInfo?.regFormLabelUse == 'Y' && (
                  <div className="cont_left_inner cont_left_inner2">
                    <Typography component="h3" variant="h3">
                      {campaignInfo?.regFormLabel}
                    </Typography>
                    {regFormInfo?.regFormIntroUse == 'Y' && (
                      <div className={cx({ more_btn_span: true, on: isExpanded || false,})}>
                        {regFormInfo?.regFormIntroUse == 'Y' && (
                          <>
                            <Typography component="p" variant="p" className="reg_desc">
                              <div
                                dangerouslySetInnerHTML={{ __html: campaignInfo?.regFormIntro }}
                                ref={(element) => (showIntroRef.current = element)}
                              />
                            </Typography>
                            {regFormInfo?.introMoreViewYn == 'Y' && (
                              <button onClick={toggleExpansion}>
                                <Typography component="span" variant="button">
                                  {isExpanded ? "간략히" : "더보기"}
                                </Typography>
                              </button>
                            )}
                          </>
                        )}

                      </div>
                    )}
                  </div>
                )}

                {(campaignInfo?.progressStandard == 'EVENT' || campaignInfo?.progressStandard == 'SESSION') && (
                  <div className="cont_left_inner cont_left_inner3">
                    {campaignInfo?.progressStandard == 'EVENT' ?
                      (campaignInfo?.eventLabelUse == 'Y') && (
                        <Stack sx={{ gap: "12px", marginBottom: "24px" }}>
                          <Typography component="h6" variant="h6">{campaignInfo?.eventLabel}</Typography>
                          {campaignInfo?.eventIntroUse == "Y" && (
                            <Typography component="p" variant="p" sx={{ paddingBottom: "12px" }}>
                              <div dangerouslySetInnerHTML={{ __html: campaignInfo?.eventIntro }} />
                            </Typography>
                          )}
                        </Stack>
                      ) : campaignInfo?.progressStandard == 'SESSION' ? (
                        (campaignInfo?.sessionLabelUse == 'Y' && regFormInfo?.sessionIntro) && (
                          <Stack sx={{ gap: "12px", marginBottom: "24px" }}>
                            <Typography component="h4" variant="h5">{campaignInfo?.sessionLabel}</Typography>
                            {(campaignInfo?.sessionIntroUse == "Y" && campaignInfo?.sessionIntro) && (
                              <Typography component="p" variant="p" sx={{ paddingBottom: "12px", marginBottom: "24px" }}>
                                <div dangerouslySetInnerHTML={{ __html: campaignInfo?.sessionIntro }} />
                              </Typography>
                            )}
                          </Stack>)
                      ) : ''}

                    <div className="flex_between">
                      {eventList?.filter(event => campaignInfo?.progressTarget != 'ONOFF' ? ['off', 'onoff'].includes(event?.progressTarget) : ['off', 'onoff', 'on'].includes(event?.progressTarget))?.length > 0 && (
                        <Checkbox
                          items={[{
                            label: <span>{regFormInfo?.offlineLabel}(<b>{eventList?.filter(e => e.offlineChecked)?.length}</b>)</span>, value: 'Y', checked: regFormInfo?.offlineAllChecked,
                            disabled: eventList?.filter(event => ['off', 'onoff'].includes(event.progressTarget)).length == eventList?.filter(event => event.progressTarget == 'off' && event.status != 'Y').length
                          }]}
                          onChange={(e) => onAllChecked(e.target.checked, 'off')}
                        />
                      )}
                      {eventList?.filter(event => campaignInfo?.progressTarget != 'ONOFF' ? ['on', 'onoff'].includes(event?.progressTarget) : ['on', 'onoff', 'off'].includes(event?.progressTarget))?.length > 0 && (
                        <Checkbox
                          items={[{
                            label: <span>{regFormInfo?.onlineLabel}(<b>{eventList?.filter(e => e.onlineChecked)?.length}</b>)</span>, value: 'Y', checked: regFormInfo?.onlineAllChecked,
                            disabled: eventList?.filter(event => ['on', 'onoff'].includes(event.progressTarget)).length == eventList?.filter(event => event.progressTarget == 'on' && event.status != 'Y').length
                          }]}
                          onChange={(e) => onAllChecked(e.target.checked, 'on')}
                        />
                      )}
                    </div>

                    {campaignInfo?.progressStandard == 'EVENT' && (
                      <ul className="event_list">
                        {eventList?.filter(item => !(item?.status == 'N' && item?.eventStatus == 'Y'))?.map((event, i) => {
                          return (
                            <li style={{ display: "flex", flexDirection: "column", gap: "16px", borderBottom: "1px solid #efefef", padding: "20px 0px" }}>
                              <Stack direction="row" alignItems="flex-start" justifyContent="space-between">
                                <Stack direction="column" alignItems="flex-start" sx={{ gap: "4px" }}>
                                  {regFormInfo?.showOnofflineMark ? (
                                    <Stack direction="row" alignItems="center">
                                      <>
                                        {((event?.onCnt > 0 && event?.offCnt) > 0 || event.onfCnt > 0) ? (<Badge text="ONLINE/OFFLINE" />)
                                          : event?.onCnt > 0 ? (<Badge text="ONLINE" />)
                                            : event?.offCnt > 0 ? (<Badge text="OFFLINE" />)
                                              : null
                                        }
                                        {event?.offCnt < 1 ? (
                                          <>
                                            {event?.liveCnt > 0 && event?.ondemandCnt > 0 ? (<Badge text="LIVE/ONDEMAND" />)
                                              : event?.liveCnt > 0 ? (<Badge text="LIVE" />)
                                                : event?.ondemandCnt > 0 ? (<Badge text="ONDEMAND" />)
                                                  : null
                                            }
                                          </>
                                        ) : ''}
                                        <span style={{ fontSize: "10px", color: "#464f60", lineHeight: "12px", fontWeight: "400" }}>{event.status == 'Y' ? '모집중' : event.status == 'W' ? '모집대기' : event.status == 'N' ? '모집마감' : ''}</span>
                                      </>

                                    </Stack>
                                  ) : null}
                                  <Typography
                                    component="h5"
                                    variant="h5"
                                    sx={{ fontSize: `${mobile ? '15px' : '16px'}`, lineHeight: `${mobile ? '22px' : '24px'}` }}
                                  // sx={{ cursor: "pointer" }}
                                  //onClick={() => { openEventInformDialog(true) }}
                                  >{event?.eventName}
                                  </Typography>
                                  {regFormInfo?.showDatetime && event?.eventDateCheck != 'Y' ? (
                                    <Typography component="span" variant="span">
                                      {event?.startDate && moment(event.startDate).format("YYYY.MM.DD(ddd) HH:mm")}{" "}
                                      {event?.endDate && ` ~ `}
                                      {event?.endDate &&
                                        (moment(event?.startDate).format("YYYY.MM.DD(ddd)") ===
                                          moment(event?.endDate).format("YYYY.MM.DD(ddd)")
                                          ? moment(event?.endDate).format("HH:mm")
                                          : moment(event?.endDate).format("YYYY.MM.DD(ddd) HH:mm"))}
                                      {(event?.startDate && event?.endDate) && ' KST'}
                                    </Typography>
                                  ) : null}

                                  {regFormInfo?.showPlace ? event?.locList.map((item, i) => {
                                    return (
                                      <div style={{ display: "flex", flexDirection: "column", gap: "2px", }}>
                                        <Typography component="span" variant="span" sx={{ display: "block" }}>
                                          {item?.name} | {item?.dtlName}
                                        </Typography>
                                      </div>
                                    )
                                  }) : ''}
                                </Stack>
                                {!mobile && (
                                  <Stack direction="row" alignItems="center" sx={{ gap: "16px" }}>
                                    {['off'].includes(event.progressTarget) && (
                                      <>
                                        <Checkbox
                                          items={[{ label: regFormInfo?.offlineLabel ?? "오프라인 참가", value: 'Y', checked: event?.offlineChecked, disabled: event?.status != 'Y' ? true : false }]}
                                          onChange={(e) => onChecked(e.target.checked, event?.idx, 'off')}
                                          height="18px"
                                        />

                                        {campaignInfo?.progressTarget == 'ONOFF' ? (
                                          <Checkbox
                                            items={[{ label: regFormInfo?.onlineLabel ?? "온라인 참가", value: 'Y', checked: event?.onlineChecked, disabled: true }]}
                                            onChange={(e) => onChecked(e.target.checked, event?.idx, 'on')}
                                            height="18px"
                                          />) : ''}
                                      </>
                                    )}
                                    {['on'].includes(event.progressTarget) && (
                                      <>
                                        {campaignInfo?.progressTarget == 'ONOFF' ? (
                                          <Checkbox
                                            items={[{ label: regFormInfo?.offlineLabel ?? "오프라인 참가", value: 'Y', checked: event?.offlineChecked, disabled: true }]}
                                            onChange={(e) => onChecked(e.target.checked, event?.idx, 'off')}
                                            height="18px"
                                          />
                                        ) : ''}
                                        <Checkbox
                                          items={[{ label: regFormInfo?.onlineLabel ?? "온라인 참가", value: 'Y', checked: event?.onlineChecked, disabled: event?.status != 'Y' ? true : false }]}
                                          onChange={(e) => onChecked(e.target.checked, event?.idx, 'on')}
                                          height="18px"
                                        />
                                      </>
                                    )}
                                    {['onoff'].includes(event.progressTarget) && (
                                      <>
                                        <Checkbox
                                          items={[{ label: regFormInfo?.offlineLabel ?? "오프라인 참가", value: 'Y', checked: event?.offlineChecked, disabled: event?.status != 'Y' ? true : false }]}
                                          onChange={(e) => onChecked(e.target.checked, event?.idx, 'off')}
                                          height="18px"
                                        />
                                        <Checkbox
                                          items={[{ label: regFormInfo?.onlineLabel ?? "온라인 참가", value: 'Y', checked: event?.onlineChecked, disabled: event?.status != 'Y' ? true : false }]}
                                          onChange={(e) => onChecked(e.target.checked, event?.idx, 'on')}
                                          height="18px"
                                        />
                                      </>
                                    )}
                                  </Stack>
                                )}
                              </Stack>

                              {regFormInfo?.showPresenter ? (
                                <Stack direction="column" sx={{ gap: "4px" }}>
                                  <RenderManagers title={'좌장'} list={event?.seatManagers} />
                                  <RenderManagers title={'연자'} list={event?.speakerManagers} />
                                  <RenderManagers title={'패널'} list={event?.panelManagers} />
                                </Stack>
                              ) : ''}

                              {mobile && (
                                <Stack direction="row" alignItems="center" justifyContent="flex-end" sx={{ gap: "16px", paddingTop: "12px" }}>
                                  {['off'].includes(event.progressTarget) && (
                                    <>
                                      <Checkbox
                                        items={[{ label: regFormInfo?.offlineLabel ?? "오프라인 참가", value: 'Y', checked: event?.offlineChecked, disabled: event?.status != 'Y' ? true : false }]}
                                        onChange={(e) => onChecked(e.target.checked, event?.idx, 'off')}
                                      />
                                      {campaignInfo?.progressTarget == 'ONOFF' ? (
                                        <Checkbox
                                          items={[{ label: regFormInfo?.onlineLabel ?? "온라인 참가", value: 'Y', checked: event?.onlineChecked, disabled: true }]}
                                          onChange={(e) => onChecked(e.target.checked, event?.idx, 'on')}
                                        />) : ''}
                                    </>
                                  )}

                                  {['on'].includes(event.progressTarget) && (
                                    <>
                                      {campaignInfo?.progressTarget == 'ONOFF' ? (
                                        <Checkbox
                                          items={[{ label: regFormInfo?.offlineLabel ?? "오프라인 참가", value: 'Y', checked: event?.offlineChecked, disabled: true }]}
                                          onChange={(e) => onChecked(e.target.checked, event?.idx, 'off')}
                                        />
                                      ) : ''}
                                      <Checkbox
                                        items={[{ label: regFormInfo?.onlineLabel ?? "온라인 참가", value: 'Y', checked: event?.onlineChecked, disabled: event?.status != 'Y' ? true : false }]}
                                        onChange={(e) => onChecked(e.target.checked, event?.idx, 'on')}
                                      />
                                    </>
                                  )}

                                  {['onoff'].includes(event.progressTarget) && (
                                    <>
                                      <Checkbox
                                        items={[{ label: regFormInfo?.offlineLabel ?? "오프라인 참가", value: 'Y', checked: event?.offlineChecked, disabled: event?.status != 'Y' ? true : false }]}
                                        onChange={(e) => onChecked(e.target.checked, event?.idx, 'off')}
                                      />
                                      <Checkbox
                                        items={[{ label: regFormInfo?.onlineLabel ?? "온라인 참가", value: 'Y', checked: event?.onlineChecked, disabled: event?.status != 'Y' ? true : false }]}
                                        onChange={(e) => onChecked(e.target.checked, event?.idx, 'on')}
                                      />
                                    </>
                                  )}
                                </Stack>
                              )}
                            </li>
                          );
                        })}
                      </ul>
                    )}
                    {campaignInfo?.progressStandard == 'SESSION' && (
                      <ul className="event_list">
                        {eventList?.filter(item => !(item?.status == 'N' && item?.sessionStatus == 'Y'))?.map((event, i) => {
                          return (
                            <li style={{ display: "flex", flexDirection: "column", gap: "16px", borderBottom: "1px solid #efefef", padding: "20px 0px" }}>
                              <Stack direction="row" alignItems="flex-start" justifyContent="space-between">
                                <Stack direction="column" alignItems="flex-start" sx={{ gap: "4px" }}>
                                  {regFormInfo?.showOnofflineMark ? (
                                    <Stack direction="row" alignItems="center">
                                      <>
                                        {event?.onfType == 'ONF' ? (<Badge text="ONLINE/OFFLINE" />)
                                          : event?.onfType == 'ON' ? (<Badge text="ONLINE" />)
                                            : event?.onfType == 'OFF' ? (<Badge text="OFFLINE" />)
                                              : null
                                        }
                                        {event?.onfType != 'OFF' ? (
                                          <>
                                            {event?.serviceType == 'LIVE' ? (<Badge text="LIVE" />) :
                                              event?.serviceType == 'ONDEMAND' ? (<Badge text="ONDEMAND" />) : null
                                            }
                                          </>
                                        ) : ''}
                                        <span style={{ fontSize: "10px", color: "#464f60", lineHeight: "12px", fontWeight: "400" }}>{event.status == 'Y' ? '모집중' : event.status == 'W' ? '모집대기' : event.status == 'N' ? '모집마감' : ''}</span>
                                      </>
                                    </Stack>
                                  ) : null}
                                  <Typography
                                    component="h5"
                                    variant="h5"
                                  // sx={{ cursor: "pointer" }}
                                  // onClick={() => { console.log }}
                                  sx={{ fontSize: `${mobile ? '15px' : '16px'}`, lineHeight: `${mobile ? '22px' : '24px'}` }}
                                  >{event?.name}
                                  </Typography>
                                  {regFormInfo?.showDatetime && event?.sessionDateCheck != 'Y'? (
                                    <Typography component="span" variant="subtext">
                                      {event?.startDate && moment(event.startDate).format("YYYY.MM.DD(ddd) HH:mm")}{" "}
                                      {event?.endDate && ` ~ `}
                                      {event?.endDate &&
                                        (moment(event?.startDate).format("YYYY.MM.DD(ddd)") ===
                                          moment(event?.endDate).format("YYYY.MM.DD(ddd)")
                                          ? moment(event?.endDate).format("HH:mm")
                                          : moment(event?.endDate).format("YYYY.MM.DD(ddd) HH:mm"))}
                                      {(event?.startDate && event?.endDate) && ' KST'}
                                    </Typography>
                                  ) : null}

                                  {regFormInfo?.showPlace && event?.loc ? (
                                    <>
                                      <Typography component="span" variant="subtext" sx={{ display: "block" }}>
                                        {event?.loc?.name} | {event?.loc?.dtlName}
                                      </Typography>
                                    </>
                                  ) : ''}
                                </Stack>
                                {!mobile && (
                                  <Stack direction="row" alignItems="center" sx={{ gap: "16px" }}>
                                    {['off'].includes(event.progressTarget) && (
                                      <>
                                        <Checkbox
                                          items={[{ label: regFormInfo?.offlineLabel ?? "오프라인 참가", value: 'Y', checked: event?.offlineChecked, disabled: event?.status != 'Y' ? true : false }]}
                                          onChange={(e) => onChecked(e.target.checked, event?.idx, 'off')}
                                          height="18px"
                                        />

                                        {campaignInfo?.progressTarget == 'ONOFF' ? (
                                          <Checkbox
                                            items={[{ label: regFormInfo?.onlineLabel ?? "온라인 참가", value: 'Y', checked: event?.onlineChecked, disabled: true }]}
                                            onChange={(e) => onChecked(e.target.checked, event?.idx, 'on')}
                                            height="18px"
                                          />) : ''}
                                      </>
                                    )}
                                    {['on'].includes(event.progressTarget) && (
                                      <>
                                        {campaignInfo?.progressTarget == 'ONOFF' ? (
                                          <Checkbox
                                            items={[{ label: regFormInfo?.offlineLabel ?? "오프라인 참가", value: 'Y', checked: event?.offlineChecked, disabled: true }]}
                                            onChange={(e) => onChecked(e.target.checked, event?.idx, 'off')}
                                            height="18px"
                                          />
                                        ) : ''}
                                        <Checkbox
                                          items={[{ label: regFormInfo?.onlineLabel ?? "온라인 참가", value: 'Y', checked: event?.onlineChecked, disabled: event?.status != 'Y' ? true : false }]}
                                          onChange={(e) => onChecked(e.target.checked, event?.idx, 'on')}
                                          height="18px"
                                        />
                                      </>
                                    )}
                                    {['onoff'].includes(event.progressTarget) && (
                                      <>
                                        <Checkbox
                                          items={[{ label: regFormInfo?.offlineLabel ?? "오프라인 참가", value: 'Y', checked: event?.offlineChecked, disabled: event?.status != 'Y' ? true : false }]}
                                          onChange={(e) => onChecked(e.target.checked, event?.idx, 'off')}
                                          height="18px"
                                        />
                                        <Checkbox
                                          items={[{ label: regFormInfo?.onlineLabel ?? "온라인 참가", value: 'Y', checked: event?.onlineChecked, disabled: event?.status != 'Y' ? true : false }]}
                                          onChange={(e) => onChecked(e.target.checked, event?.idx, 'on')}
                                          height="18px"
                                        />
                                      </>
                                    )}
                                  </Stack>
                                )}
                              </Stack>
                              {event?.manager && (
                                <>
                                  {(campaignInfo?.progressStandard == 'SESSION' && regFormInfo?.showPresenter) ? (
                                    <div style={{ display: "flex", alignItems: "flex-start" }}>
                                      <Typography component="small" variant="subtext6" sx={{ minWidth: "33px" }}>
                                        {event?.manager?.mgrType == 'SEAT' ? '좌장' :
                                          event?.manager?.mgrType == 'SPEAKER' ? '연자' :
                                            event?.manager?.mgrType == 'PANEL' ? '패널' : ''
                                        }
                                      </Typography>
                                      <div style={{ display: "flex", flexDirection: "column", gap: "3px" }}>
                                        <Typography component="span" variant="span" sx={{ display: "block" }}>
                                          {event?.manager?.name ? `${event?.manager?.name} (${event?.manager?.companyName})` : ''}
                                        </Typography>
                                      </div>
                                    </div>
                                  ) : ''}
                                </>
                              )}
                              {mobile && (
                                <Stack direction="row" alignItems="center" justifyContent="flex-end" sx={{ gap: "16px", paddingTop: "12px" }}>
                                  {['off'].includes(event.progressTarget) && (
                                    <>
                                      <Checkbox
                                        items={[{ label: regFormInfo?.offlineLabel ?? "오프라인 참가", value: 'Y', checked: event?.offlineChecked, disabled: event?.status != 'Y' ? true : false }]}
                                        onChange={(e) => onChecked(e.target.checked, event?.idx, 'off')}
                                        height="18px"
                                      />
                                      {campaignInfo?.progressTarget == 'ONOFF' ? (
                                        <Checkbox
                                          items={[{ label: regFormInfo?.onlineLabel ?? "온라인 참가", value: 'Y', checked: event?.onlineChecked, disabled: true }]}
                                          onChange={(e) => onChecked(e.target.checked, event?.idx, 'on')}
                                          height="18px"
                                        />) : ''}
                                    </>
                                  )}

                                  {['on'].includes(event.progressTarget) && (
                                    <>
                                      {campaignInfo?.progressTarget == 'ONOFF' ? (
                                        <Checkbox
                                          items={[{ label: regFormInfo?.offlineLabel ?? "오프라인 참가", value: 'Y', checked: event?.offlineChecked, disabled: true }]}
                                          onChange={(e) => onChecked(e.target.checked, event?.idx, 'off')}
                                          height="18px"
                                        />
                                      ) : ''}
                                      <Checkbox
                                        items={[{ label: regFormInfo?.onlineLabel ?? "온라인 참가", value: 'Y', checked: event?.onlineChecked, disabled: event?.status != 'Y' ? true : false }]}
                                        onChange={(e) => onChecked(e.target.checked, event?.idx, 'on')}
                                        height="18px"
                                      />
                                    </>
                                  )}

                                  {['onoff'].includes(event.progressTarget) && (
                                    <>
                                      <Checkbox
                                        items={[{ label: regFormInfo?.offlineLabel ?? "오프라인 참가", value: 'Y', checked: event?.offlineChecked, disabled: event?.status != 'Y' ? true : false }]}
                                        onChange={(e) => onChecked(e.target.checked, event?.idx, 'off')}
                                        height="18px"
                                      />
                                      <Checkbox
                                        items={[{ label: regFormInfo?.onlineLabel ?? "온라인 참가", value: 'Y', checked: event?.onlineChecked, disabled: event?.status != 'Y' ? true : false }]}
                                        onChange={(e) => onChecked(e.target.checked, event?.idx, 'on')}
                                        height="18px"
                                      />
                                    </>
                                  )}
                                </Stack>
                              )}
                            </li>
                          );
                        })}
                      </ul>
                    )}
                  </div>
                )}

                {inputItems?.filter((item) =>
                  (item?.isTermsGroup == 'Y' && item?.children?.length > 0) ||
                  item?.children?.length > 0
                  && item?.onlyHost != 'Y'
                  && (
                    item?.defaultYn == 'Y'
                    || (eventList?.filter((e) => e.offlineChecked)?.length > 0 && ['OFF', 'ONOFF'].includes(item?.onOffUse))
                    || (eventList?.filter((e) => e.onlineChecked)?.length > 0 && ['ON', 'ONOFF'].includes(item?.onOffUse))
                  )
                )?.map((item, index) => {

                  return (
                    <div className="cont_left_inner cont_left_inner4" style={{ display: "flex", flexDirection: "column", gap: "32px" }}>
                      <Stack sx={{ gap: "12px" }}>
                        <Typography component="h6" variant="h6">{item?.name}</Typography>
                        {item?.description && (
                          <Typography component="p" variant="p" sx={{ marginBottom: "12px" }}>{item?.description}</Typography>
                        )}
                      </Stack>
                      <Stack sx={{gap:`${mobile?'32px':'24px'}`}}>
                        {(item?.isTermsGroup == "Y" && item?.children?.length >= 2) && (
                          <Checkbox
                            space={mobile?"":true}
                            items={[{ label: item?.termsAllAgree, value: 'Y', checked: regFormInfo?.termsAllChecked }]}
                            styles={{ width: "100%", borderBottom:"1px solid #dedede", marginBottom:"4px" }}
                            onChange={(e) => onTermsAllChecked(e.target.checked)}
                          />
                        )}
                        <ChildInput item={item} isTermsGroup={item?.isTermsGroup} />
                      </Stack>
                    </div>
                  )
                })}

                {regFormInfo?.btnLabel ? (
                <Stack direction="column" justifyContent="flex-end" alignItems="center" sx={{ gap: "10px", marginBottom: "60px" }}>
                  <Button onClick={() => openReg()} sx={{ width: "100%", padding: "10px" }}>{regFormInfo?.btnLabel}</Button>
                  {/* <Typography component="span" variant="span">신청을 완료해 주시기 바랍니다.</Typography> */}
                </Stack>
                ) : ''}
              </div>

              <div className="cont_right">
                <div className="sym_cs">
                  {campaignInfo?.csName && (<Typography component="h6" variant="h7">{campaignInfo?.csName}</Typography>)}
                  {campaignInfo?.csDescription && (<div dangerouslySetInnerHTML={{ __html: campaignInfo?.csDescription }} />)}
                </div>
              </div>
            </div>
          </section>
        </Container>

        <PreRegisterDialog
          open={preregOpen}
          setOpen={setPreregOpen}
          // eventList={eventList.filter((e) => e.onlineChecked == true || e.offlineChecked)}
          eventList={eventList}
          campaignIdx={campaignIdx}
          myPreRegInfoList={inputItems}
          progressStandard={campaignInfo?.progressStandard}
          eventLabel={campaignInfo?.eventLabel}
          sessionLabel={campaignInfo?.sessionLabel}
          siteEventLabel={campaignInfo?.siteEventLabel}
          saveList={curPreRegInfoList}
          mgrName={mgrName}
          selectConnectPath={selectConnectPath}
        />
        <EventDialog
          open={eventInformOpen}
          setOpen={setEventInformOpen}
          eventList={eventList}
        />
        {brandingInfo?.footer && (<Footer data={brandingInfo?.footer} />)}
        <DynamicStyles />
      </ThemeProvider>
    )
  }

}

const Container = styled.div`
  margin-top:60px;
  .wid_1180{max-width:1180px; margin: 0 auto; width:100%; }
  .content{display:flex; padding:20px; column-gap:40px;}
  .slide_box{ margin: 0px auto; padding: 20px;}
  
  .slide_box .swiper_container{ border-radius:8px;}
  .slide_box .swiper_container .swiper-pagination{bottom:0px; display:flex; justify-content:center; gap:6px;}
  .slide_box .swiper_container .swiper-pagination-bullet{width:12px; height:12px; opacity:1; background:#fff; border:2px solid #464f60; box-sizing:border-box; margin:0;}
  .slide_box .swiper_container .swiper-pagination-bullet-active{background:#464f60;}
  .cont_left{width:calc(100% - 300px); display:flex; flex-direction:column; gap:60px;}
  .cont_left_inner1{display:flex; flex-direction:column; gap:24px}
  .cont_left_inner2{display:flex; flex-direction:column; gap:12px}
  .cont_left_inner > h3{padding:7px 0; border-bottom:2px solid #373741; display:flex; align-items:center; justify-content:space-between; }
  .cont_left_inner3 h6,.cont_left_inner4 > div > h6{padding:7px 0; border-bottom:1px solid #d9d9d9; display:flex; align-items:center; justify-content:space-between;}
  .cont_left_inner4 .section_box:first-child{padding-top:24px;}
  .cont_left_inner .inner_box h5{display:flex; align-items:center; justify-content:space-between;}
  .cont_left_inner .more_btn_span{display:flex; flex-direction:column; align-items:flex-start; gap:8px}
  .cont_left_inner .more_btn_span p.reg_desc{
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  .cont_left_inner .more_btn_span.on p.reg_desc{
    display: block;
    overflow: unset;
  }
  .cont_left_inner .more_btn_span button span{
    display: block;
    padding: 4px 0 0 0;
    border-bottom: 1px dotted #5959EF;
    margin-bottom: 5px;
  }
  .flex_between{display:flex; align-items:center; justify-content:flex-end; gap:16px; padding:11px 0 0; border-bottom:1px solid #efefef;}
  .flex_between > div{display:flex;}
  .flex_between > div:first-child > span:first-child:after{
      content: "";
      display: inline-block;
      width: 1px;
      height: 10px;
      background: #d9d9d9;
      margin: 0 12px;}
  .flex_between > div:nth-child(2){gap:16px;}
  .event_list li small:first-child:after{
      content: "";
      display: inline-block;
      width: 1px;
      height: 10px;
      background: #d9d9d9;
      margin: 0 4px;
  }
  .cont_right{width:300px; display:flex; flex-direction:column; gap:24px; }
  .cont_right .sym_info{background:#f7f7fa; border:1px solid #eaeaea; border-radius:4px; padding:20px;}
  .cont_right .sym_info ul{display:flex; flex-direction:column; gap:24px; }
  .cont_right .sym_info ul li h6{display:flex; align-items:center; gap:4px; margin-bottom:8px;}
  .cont_right .sym_info ul li div small:first-child:after{
    content: "";
    display: inline-block;
    width: 1px;
    height: 10px;
    background: #d9d9d9;
    margin: 0 10px;
    }
  .cont_right .sym_cs{display:flex; flex-direction:column; gap:10px; border:1px solid #e4e4e4; border-radius:6px; padding:20px;}
  .cont_right .sym_cs h6{font-size:16px; line-height:24px; font-weight:700; color:#333333;}
  .cont_right .sym_cs div{ color:#666666;}
  .detail_banner_box_con h6{margin-bottom: 12px;}



  @media screen and (max-width: 1024px) {
    .content{flex-direction:column; margin-bottom:60px;padding-bottom:20px;}
    .cont_right{width: 100%;}
    .cont_right .banner_cont ul li{width: 33.33%}
  }
  @media screen and (max-width: 767px) {
    .cont_left{width:100%; gap:50px;}
    .cont_left_inner1 > h2{font-size:26px;}
    .cont_right .banner_cont ul li{width: 50%}
  }
  @media screen and (max-width: 479px) {
    .cont_right .banner_cont ul li{width: 100%}
  }
`