import React, {useState, Fragment,useRef,useEffect } from 'react';
import styled from "styled-components";
import cx from "classnames";
import { Input, Typography } from "@mui/material";

const HeaderComponent = (props) => { 
  const [scrollY, setScrollY] = useState(0);

  const { logoImgUrl, mobile, onEditInfoClick } = props;

  const handleScroll = () => {
    const { pageYOffset } = window;
    setScrollY(pageYOffset);
  };
  const documentRef = useRef(document);
  useEffect(() => {
    documentRef.current.addEventListener("scroll", handleScroll);
  });


  return (
    <Fragment>
      <Header>
        <h1>{logoImgUrl && <a><img src={logoImgUrl} alt="로고" /></a>}</h1>
        <ul>
          <li><Typography component="span" variant="subtext2" onClick={onEditInfoClick} style={{ cursor: 'pointer' }}>신청정보 수정</Typography></li>
          <li><Typography component="span" variant="subtext2">사이트 바로가기</Typography></li>
        </ul>
      </Header>
    </Fragment>
  )
}

export default HeaderComponent;

const Header = styled.header`
  position:fixed;
  top:0;
  left:0;
  display:flex;
  height:60px;
  border-bottom:1px solid #efefef;
  width:100%;
  align-items: center;
  justify-content:space-between;
  padding:0 20px;
  z-index: 10;
  background: #fff;
  ul,ul li{display:flex}
  ul li{width:100%; align-items:center;}
  ul li:first-child:after{content:""; display:inline-block; width:1px; height:10px; background:#d9d9d9; margin:0 12px; }
  h1{width:100%;}
  span{
    max-height: 40px;
    width: max-content;
    max-width: 240px;
    position: relative;
    height: 40px;
    cursor:default;
    display:flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    cursor:pointer;
  }
  a img{
    max-width: inherit;
    max-height: inherit;
    position: absolute;
    top: 50%;
    left: 0px;
    transform: translateY(-50%);
  }
  @media (max-width:767px){
    a{
      max-width: 160px;
      max-height: 40px;
      width: inherit;
    }
  }
  
`;